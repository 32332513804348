import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { setToken, removeToken } from '../utils/token'; // Assuming you have a utils/token.js file

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 320px;
  padding: 10px;
  margin: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const RegisterButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/login`, { username, password });
      if (response.data.token) {
        setToken(response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Save the user in local storage
        window.location.href = '/dashboard';
      } else {
        setError('Login fehlgeschlagen. Bitte überprüfe deine Anmeldedaten.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Ungültige Anmeldedaten. Bitte überprüfe deine Anmeldedaten und versuche es erneut.');
    }
  };

  return (
    <Container>
      <Title>Login</Title>
      <Input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <Input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Button onClick={handleLogin}>Login</Button>
      <RegisterButton onClick={() => window.location.href = '/register'}>Register</RegisterButton>
    </Container>
  );
};

export default Login;
