import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import styled from 'styled-components';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL;

const DateInput = styled.input`
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  background-color: #3f51b5;
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #303f9f;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ClickStats = () => {
  const [clickStats, setClickStats] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = (start = '', end = '') => {
    let url = `${API_URL}/api/stats/clicks`;
    if (start && end) {
      url += `?startDate=${start}&endDate=${end}`;
    }

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setClickStats(data))
      .catch(error => console.error('Error fetching click stats:', error));
  };

  const handleFilterClick = () => {
    const formattedStartDate = moment(startDate).format('DD-MM-YYYY');
    const formattedEndDate = moment(endDate).format('DD-MM-YYYY');
    fetchStats(formattedStartDate, formattedEndDate);
  };

  const handleResetClick = () => {
    setStartDate('');
    setEndDate('');
    fetchStats();
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <DateInput
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <DateInput
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button onClick={handleFilterClick}>Filter</Button>
        <Button onClick={handleResetClick}>Reset</Button>
      </div>
      {clickStats.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          Es liegen für diesen Zeitraum leider keine Ergebnisse vor.
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {clickStats.map((stat, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                {stat.logo && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={stat.logo}
                    alt={`${stat.casino_name} logo`}
                  />
                )}
                <CardContent>
                  <Typography variant="h6" component="div">
                    {stat.casino_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {stat.count} clicks
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default ClickStats;
