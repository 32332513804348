import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  padding: 20px;
  text-align: center;
  color: white;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>&copy; 2024 GambleBonusWorld. All rights reserved.</p>
      <p>18+ | <a href="http://www.begambleaware.org" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>www.begambleaware.org</a></p>
    </FooterContainer>
  );
};

export default Footer;
