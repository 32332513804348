import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';

// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    overflow: hidden;
  }  
`;

const TournamentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  height: calc(100vh - 10px);
  box-sizing: border-box;
  overflow-y: hidden;
`;

const WinnersDisplayAnimation = keyframes`
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.1); }
  100% { transform: translate(-50%, -50%) scale(1); }
`;

const WinnersDisplay = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 20px;
  color: white;
  text-align: center;
  animation: ${WinnersDisplayAnimation} 2s infinite;
  width: 25%;
  height: auto;
`;

const WinnerHeader = styled.h2`
  margin-bottom: 20px;
`;

const WinnerPlayerCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const WinnerImgWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const WinnerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const WinnerPlayerName = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const TournamentSettings = ({ prizes }) => {
  return (
    <div style={{ position: 'absolute', top: 10, right: 10, background: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
      <h3>Preisgelder:</h3>
      <ul>
        {prizes.map((prize, index) => (
          <li key={index}>
            Platz {index + 1}: {prize.value} {prize.currency}
          </li>
        ))}
      </ul>
    </div>
  );
};

const RoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 33%;
  height: 100%;
`;

const CompetitionDisplay = styled.div`
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  justify-content: space-between;
  height: 120px; // Reduzierte Höhe
    transform: scale(1.3);
`;

const PlayerWrapper = styled.div`
  padding: 5px;
  background-color: rgba(43, 43, 43, 0.61);
  border-radius: 8px;
  color: white;
  position: relative;
  width: 45%;
  height: 100%; // Anpassung an die neue Höhe des CompetitionDisplay
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayerCard = styled.div`
  background-color: rgb(165, 167, 172);
  color: black;
  padding: 5px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90%; // Anpassung an die neue Höhe des PlayerWrapper
  box-sizing: border-box;
  position: relative;
`;

const ImgWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  i {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 45px);
  height: 100%;
`;

const PlayerName = styled.span`
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const GameName = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

const PlayerValue = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: gold;
`;

const Separator = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  .text {
    margin: 2px 5px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    color: rgb(255, 68, 43);
  }

  .line {
    height: 30px;
    width: 2px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-bottom: 5px;
  }

  .line2 {
    height: 30px;
    width: 2px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-top: 5px;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

const ViewerView = () => {
  const [singleTournamentState, setSingleTournamentState] = useState({ round: 1, players: [], allRounds: [] });
  const [prizes, setPrizes] = useState([]);
  const [winners, setWinners] = useState([]);
  
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tournamentStateRes = await axios.get(`${API_URL}/singleTournamentState`);
        const settingsRes = await axios.get(`${API_URL}/tournamentSettings`);
  
        setSingleTournamentState(tournamentStateRes.data);
        setPrizes(settingsRes.data.prizes || []);
  
        if (tournamentStateRes.data.winners && tournamentStateRes.data.winners.length > 0) {
          setWinners(tournamentStateRes.data.winners);
        }
  
        console.log('Single Tournament State:', tournamentStateRes.data);
        console.log('Tournament Settings:', settingsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    const interval = setInterval(fetchData, 5000);
  
    return () => clearInterval(interval);
  }, [API_URL]);

  const renderPlayerCard = (player, roundIndex) => {
    if (!player) {
      return (
        <PlayerCard className="skeleton">
          <ImgWrapper>
            <i className="fas fa-question"></i>
          </ImgWrapper>
          <Details>
            <PlayerName>Empty</PlayerName>
            <InfoWrapper>
              <GameName>Slot</GameName>
              <PlayerValue>
                <span style={{ fontSize: '14px', color: 'grey', marginRight: '10px' }}>
                  (0.00 €)
                </span>
                0x
              </PlayerValue>
            </InfoWrapper>
          </Details>
        </PlayerCard>
      );
    }

    const currentRound = singleTournamentState.round;
    let value, buyValue, game;

    if (roundIndex < currentRound - 1) {
      // For previous rounds, use the stored values from allRounds
      const playerInPreviousRound = singleTournamentState.allRounds[roundIndex].find(p => p && p.username === player.username);
      if (playerInPreviousRound) {
        value = playerInPreviousRound.value || '0';
        buyValue = playerInPreviousRound.buyValue || '0';
        game = playerInPreviousRound.game || 'Slot';
      } else {
        value = '0';
        buyValue = '0';
        game = 'Slot';
      }
    } else {
      // For the current round, use the current values
      value = player.value || '0';
      buyValue = player.buyValue || '0';
      game = player.game || 'Slot';
    }
  
    const xFactor = parseFloat(buyValue) > 0 ? (parseFloat(value) / parseFloat(buyValue)).toFixed(2) : '0';
  
    return (
      <PlayerCard className="playerCard">
        <ImgWrapper>
          {player.profileImageUrl ? (
            <img src={player.profileImageUrl} alt={player.username} className="profileImg" />
          ) : (
            <i className="fas fa-user"></i>
          )}
        </ImgWrapper>
        <Details>
          <PlayerName>{player.username}</PlayerName>
          <GameName>{game}</GameName>
          <InfoWrapper>
            
            <PlayerValue>
              <span style={{ fontSize: '14px', color: 'grey', marginRight: '10px' }}>
                ({parseFloat(value).toFixed(2)} €)
              </span>
              {xFactor}x
            </PlayerValue>
          </InfoWrapper>
        </Details>
      </PlayerCard>
    );
  };
  
  const renderRound = (players, roundIndex, totalRounds) => {
    const roundSize = Math.pow(2, totalRounds - roundIndex);
    const paddedPlayers = players ? players.concat(Array(roundSize - players.length).fill(null)) : Array(roundSize).fill(null);
    return (
      <RoundWrapper key={roundIndex}>
        {paddedPlayers.map((player, index) =>
          index % 2 === 0 ? renderMatchup(player, paddedPlayers[index + 1], index / 2, roundIndex) : null
        )}
      </RoundWrapper>
    );
  };
  
  const renderMatchup = (player1, player2, matchIndex, roundIndex) => {
    return (
      <CompetitionDisplay key={matchIndex}>
        <PlayerWrapper>
          {renderPlayerCard(player1, roundIndex)}
        </PlayerWrapper>
        <Separator>
          <div className="line"></div>
          <span className="text">VS</span>
          <div className="line2"></div>
        </Separator>
        <PlayerWrapper>
          {renderPlayerCard(player2, roundIndex)}
        </PlayerWrapper>
      </CompetitionDisplay>
    );
  };

  const renderTournamentBracket = () => {
    const totalRounds = 3; // We want to display only the first 3 rounds
    const rounds = [];

    for (let i = 0; i < totalRounds; i++) {
      const roundPlayers = singleTournamentState.allRounds && singleTournamentState.allRounds[i] 
        ? singleTournamentState.allRounds[i] 
        : [];
      rounds.push(renderRound(roundPlayers, i, totalRounds));
    }

    return rounds;
  };

  return (
    <>
      <GlobalStyle />
      <TournamentWrapper>
        {renderTournamentBracket()}
        <TournamentSettings prizes={prizes} />
        {winners.length > 0 && (
          <WinnersDisplay>
            <WinnerHeader>Final Winner</WinnerHeader>
            {winners.map((winner, index) => (
              <WinnerPlayerCard key={index}>
                <WinnerImgWrapper>
                  {winner.profileImageUrl ? (
                    <img src={winner.profileImageUrl} alt={winner.username} />
                  ) : (
                    <i className="fas fa-user"></i>
                  )}
                </WinnerImgWrapper>
                <WinnerDetails>
                  <WinnerPlayerName>{winner.username}</WinnerPlayerName>
                </WinnerDetails>
              </WinnerPlayerCard>
            ))}
          </WinnersDisplay>
        )}
      </TournamentWrapper>
    </>
  );
};

export default ViewerView;