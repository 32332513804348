// MainContent.js
import React from 'react';
import styled from 'styled-components';
import CombinedContainer from './CombinedContainer';

const MainContainer = styled.main`
  padding: 20px;
  text-align: center;
  width: 100%;
`;

const MainContent = ({ bonusEntries, filters, setFilters }) => {
  return (
    <MainContainer>
      <CombinedContainer bonusEntries={bonusEntries} filters={filters} setFilters={setFilters} />
    </MainContainer>
  );
};

export default MainContent;
