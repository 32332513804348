import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Container as MuiContainer, Box, Button as MuiButton, Typography, Paper, Divider } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled, { keyframes } from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { useMediaQuery, ClickAwayListener } from '@mui/material';
import GiveawayImg from '../assets/Giveaway.png';
import BonusEntry from './BonusEntry';
import checkIcon from "../assets/check.png";
import closeIcon from "../assets/close.png";
import plusIcon from "../assets/plus.png";
import bankTransferIcon from '../assets/Zahlungsmethoden/bank-transfer.svg';
import cryptoIcon from '../assets/Zahlungsmethoden/crypto.svg';
import mastercardIcon from '../assets/Zahlungsmethoden/master-card.png';
import mifinityIcon from '../assets/Zahlungsmethoden/mifinity.svg';
import neosurfIcon from '../assets/Zahlungsmethoden/neosurf.svg';
import netellerIcon from '../assets/Zahlungsmethoden/neteller.svg';
import paysafeIcon from '../assets/Zahlungsmethoden/paysafecard.svg';
import skrillIcon from '../assets/Zahlungsmethoden/skrill.svg';
import visaIcon from '../assets/Zahlungsmethoden/visa.png';

const iconMap = {
  "Bank Überweisung": bankTransferIcon,
  "Crypto": cryptoIcon,
  "Master Card": mastercardIcon,
  "MiFINITY": mifinityIcon,
  "Neosurf": neosurfIcon,
  "Neteller": netellerIcon,
  "Paysafe": paysafeIcon,
  "Skrill": skrillIcon,
  "Visa Card": visaIcon
};

// Styled-components styles
const StyledContainer = styled.div`
  background-color: #e9ecef;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: left;
  position: relative;
  width: 80%;
  border: 1px solid #000;
`;

const NewTag = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #3498db;
  color: white;
  padding: 5px ${props => (props.isDama ? '5px' : '40px')};
  font-weight: bold;
  transform: rotate(-45deg);
  transform-origin: top left;
  z-index: 1;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Brand = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rotate = keyframes`
  0%, 100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }
  75% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
`;

const Tag = styled.span`
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 5px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #08f, #f03) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: ${rotate} 4s linear infinite;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  background-color: #000;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5);
`;

const CardColGroup = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-evenly;
  margin: 0 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Stat = styled.div`
  text-align: center;
  margin: 0 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const SubtitleLarge = styled.p`
  font-size: 2em;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Small = styled.small`
  color: #7f8c8d;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const TermsLink = styled.small`
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const Button = styled.a`
  background-color: #7526cd;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #27ae60;
  }
`;

const MoreInfoButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-top: 5px;

  img {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }
`;

const ExtraInfoContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  margin-top: 20px;
  justify-content: space-between;
`;

const InfoList = styled.div`
  flex: 1;
  margin-right: 20px;
  padding-left: 110px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const PaymentTypeIcons = styled.div`
  flex: 1;

  .subtitle {
    margin-bottom: 10px;
  }

  .icon-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const BankingOption = styled.span`
  display: inline-block;
  margin: 5px;

  img {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const CardColGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

const CenteredBox = muiStyled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const FormGroup = muiStyled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ImageContainer = muiStyled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
}));

const Image = muiStyled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const StyledMuiContainer = muiStyled(MuiContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: 0,
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > *': {
    width: '100%',
  },
}));

const HighlightText = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const InfoPaper = muiStyled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  background: theme.palette.grey[50],
}));

const FullWidthContainer = muiStyled('div')({
  width: '100%',
  marginBottom: '20px',
});

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
`;

const CountdownItem = styled.div`
  margin: 0 10px;
  text-align: center;
`;

const CountdownValue = styled.span`
  display: block;
  font-size: 1.5em;
  color: #7526cd;
`;

const CountdownLabel = styled.span`
  display: block;
  font-size: 0.5em;
  text-transform: uppercase;
`;

const Giveaway = () => {
  const [casinoName, setCasinoName] = useState('');
  const [casinoUsername, setCasinoUsername] = useState('');
  const [discordUsername, setDiscordUsername] = useState('');
  const [platform, setPlatform] = useState('');
  const [platformUsername, setPlatformUsername] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [bonusEntries, setBonusEntries] = useState([]);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    fetchBonusEntries();

    const calculateTimeLeft = () => {
      const now = new Date();
      const endDate = new Date(2024, 8, 29, 23, 59, 59); // Monate sind 0-indiziert, daher 8 für September
      
      const difference = endDate - now;

      let timeLeft = {};

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        timeLeft = { days, hours, minutes, seconds };
      }

      return timeLeft;
    };


    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  const fetchBonusEntries = async () => {
    try {
      const response = await fetch(`${API_URL}/api/giveaway-entries`);
      if (response.ok) {
        const data = await response.json();
        setBonusEntries(data);
      } else {
        console.error('Failed to fetch bonus entries');
      }
    } catch (error) {
      console.error('Error fetching bonus entries:', error);
    }
  };

  const handleSubmit = async () => {
    const data = {
      casinoName,
      casinoUsername,
      discordUsername,
      platform,
      platformUsername,
    };

    try {
      const response = await fetch(`${API_URL}/api/giveaway`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setStatusMessage('Du wurdest erfolgreich für das Giveaway registriert');
        setIsSuccess(true);
        // Reset all fields
        setCasinoName('');
        setCasinoUsername('');
        setDiscordUsername('');
        setPlatform('');
        setPlatformUsername('');
      } else {
        setStatusMessage('Fehler beim übermitteln der Daten. Bitte fülle alle Felder aus!');
        setIsSuccess(false);
      }
    } catch (error) {
      setStatusMessage('Beim Übermitteln der Daten ist ein Fehler aufgetreten');
      setIsSuccess(false);
    }
  };

  return (
    <StyledMuiContainer maxWidth="md">
      <InfoPaper elevation={3}>
        <Typography variant="h4" gutterBottom align="center">
          Großes Giveaway Event!
        </Typography>
        
        <CountdownContainer>
          {Object.keys(timeLeft).length === 0 ? (
            <Typography variant="h5">Giveaway beendet!</Typography>) : (
              <>
                {['days', 'hours', 'minutes', 'seconds'].map((interval) => (
                  <CountdownItem key={interval}>
                    <CountdownValue>{timeLeft[interval]}</CountdownValue>
                    <CountdownLabel>{interval}</CountdownLabel>
                  </CountdownItem>
                ))}
              </>
            )}
          </CountdownContainer>
  
          <Typography variant="h6" gutterBottom>
            Es ist soweit und du kannst dabei sein!
          </Typography>
          <Typography variant="body1" paragraph>
            Sichere dir <HighlightText component="span">2 Tickets</HighlightText> für jede erste Einzahlung auf eine der unten aufgeführten Seiten! Für jede weitere Einzahlung von mind. 30 € erhaltet ihr jeweils <HighlightText component="span">1 weiteres Ticket</HighlightText> . 
          </Typography>
          <Typography variant="body1" paragraph>
            <HighlightText component="span">Beispiel:</HighlightText>
         </Typography>
         <Typography> 
            Bei einer Ersteinzahlung von 90 € erhältst du <HighlightText component="span">4 Tickets</HighlightText> (2 für die Ersteinzahlung + 2 zusätzliche für je 30 €).
         </Typography>
         <br></br>
          <Typography variant="h5" gutterBottom>
            Gewinne:
          </Typography>
          <Typography variant="body1">
            🥇 1. Platz: <HighlightText component="span">3.000 € RAWCash</HighlightText><br />
            🥈 2. Platz: <HighlightText component="span">1.000 € RAWCash</HighlightText><br />
            🥉 3. Platz: <HighlightText component="span">500 € RAWCash</HighlightText>
          </Typography>
          <Typography variant="body2" style={{ marginTop: '1rem' }}>
            Weitere Platzierungen und Gewinne findest du unten.
          </Typography>
          <Divider style={{ margin: '1rem 0' }} />
          <Typography variant="body1" paragraph>
            <HighlightText component="span">WICHTIG:</HighlightText> DIE CASINO REGISTRIERUNG MUSS ÜBER UNSEREN LINK ERFOLGEN!
          </Typography>
          <Typography variant="body1">
            Das Giveaway läuft bis zum <HighlightText component="span">30.09.2024</HighlightText>.
          </Typography>
          <Typography variant="body1" style={{ marginTop: '1rem' }}>
            Zahle hier beliebig oft 30€ als Erst- oder Folgeeinzahlung ein:
          </Typography>
        </InfoPaper>
        
        {bonusEntries.map((entry, index) => (
          <BonusEntry key={index} {...entry} />
        ))}
  
        <Box component="form" noValidate autoComplete="off">
          <FormGroup>
            <TextField
              fullWidth
              select
              label="Casino Name"
              value={casinoName}
              onChange={(e) => setCasinoName(e.target.value)}
              variant="outlined"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="Leon">Leon</MenuItem>
              <MenuItem value="SpinFever">SpinFever</MenuItem>
              <MenuItem value="Rooli">Rooli</MenuItem>
              <MenuItem value="WildTornado">WildTornado</MenuItem>
              <MenuItem value="MelinaCasino">MelinaCasino</MenuItem>
              <MenuItem value="RTBet">RTBet</MenuItem>
              <MenuItem value="Neon54">Neon54</MenuItem>
              <MenuItem value="SlotsPalace">SlotsPalace</MenuItem>
              <MenuItem value="MyEmpire">MyEmpire</MenuItem>
              <MenuItem value="FortunePanda">FortunePanda</MenuItem>
            </TextField>
          </FormGroup>
          <FormGroup>
            <TextField
              fullWidth
              label="Casino Username"
              value={casinoUsername}
              onChange={(e) => setCasinoUsername(e.target.value)}
              variant="outlined"
            />
          </FormGroup>
          <FormGroup>
            <TextField
              fullWidth
              label="Discord Username"
              value={discordUsername}
              onChange={(e) => setDiscordUsername(e.target.value)}
              variant="outlined"
            />
          </FormGroup>
          <FormGroup>
            <TextField
              fullWidth
              select
              label="Platform"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              variant="outlined"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="Twitch">Twitch</MenuItem>
              <MenuItem value="Kick">Kick</MenuItem>
              <MenuItem value="YouTube">YouTube</MenuItem>
            </TextField>
          </FormGroup>
          <FormGroup>
            <TextField
              fullWidth
              label="Platform Username"
              value={platformUsername}
              onChange={(e) => setPlatformUsername(e.target.value)}
              variant="outlined"
            />
          </FormGroup>
          {statusMessage && (
            <Typography variant="body1" color={isSuccess ? 'green' : 'error'} mb={2}>
              {statusMessage}
            </Typography>
          )}
          <FormGroup>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginTop: '16px' }}
            >
              Abschicken
            </Button>
          </FormGroup>
        </Box>
        <ImageContainer>
          <Image src={GiveawayImg} alt="GBW Logo" />
        </ImageContainer>
      </StyledMuiContainer>
    );
  };
  
  export default Giveaway;