import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  backgroundColor: '#f8f9fa',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}));

const SubheaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <HeaderTypography 
          variant={isMobile ? "h4" : "h3"} 
          component="h1" 
          gutterBottom
          style={{ wordBreak: 'break-word' }}
        >
          GambleBonusWorld: Deine Top-Adresse für Casino Boni und Slot-Erlebnisse
        </HeaderTypography>
        
        <StyledTypography variant="body1" paragraph>
          Willkommen bei GambleBonusWorld, deiner vertrauenswürdigen Anlaufstelle für erstklassige Online Casino Boni und eine lebendige Slot-Community. Unsere Plattform bietet dir einen umfassenden Überblick über die besten Angebote und Möglichkeiten in der Welt des Online-Glücksspiels.
        </StyledTypography>

        <SubheaderTypography variant={isMobile ? "h5" : "h4"} gutterBottom>
          Warum GambleBonusWorld deine erste Wahl sein sollte
        </SubheaderTypography>

        <List>
          {[
            "Exklusive Casino Boni: Wir präsentieren dir eine sorgfältig kuratierte Auswahl an lukrativen Bonusangeboten führender Online Casinos. Von Willkommensboni bis hin zu Free Spins – bei uns findest du stets die aktuellsten und attraktivsten Promotionen.",
            "Vielfältige Slot-Auswahl: Tauche ein in die faszinierende Welt der Online Slots. Unser Team testet regelmäßig neue Spiele und teilt ausführliche Bewertungen, damit du immer über die neuesten Trends und besten Gewinnchancen informiert bist.",
            "Aktive Community: Werde Teil unserer lebhaften Gemeinschaft von Slot-Enthusiasten. Tausche Erfahrungen aus, teile Strategien und finde Gleichgesinnte in unseren Foren und Chatrooms.",
            "Vertrauenswürdige Informationen: Unsere Experten recherchieren gründlich und stellen sicher, dass du immer Zugang zu akkuraten und aktuellen Informationen über Online Casinos, Bonusbedingungen und Auszahlungsquoten hast.",
            "Verantwortungsvolles Spielen: Wir legen großen Wert auf verantwortungsvolles Glücksspiel. Finde bei uns hilfreiche Ressourcen und Tipps, um dein Spielverhalten im Griff zu behalten.",
            "Mobile Optimierung: Genieße unsere Plattform jederzeit und überall – perfekt optimiert für dein Smartphone oder Tablet.",
            "Transparenz und Fairness: Wir arbeiten nur mit seriösen und lizenzierten Online Casinos zusammen, um dir ein sicheres und faires Spielerlebnis zu garantieren."
          ].map((text, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>

        <Box my={4}>
          <SubheaderTypography variant={isMobile ? "h5" : "h4"} gutterBottom>
            Entdecke die Vorteile von GambleBonusWorld
          </SubheaderTypography>
          
          <Grid container spacing={2}>
            {[
              "Tägliche Updates: Bleib immer auf dem Laufenden mit unseren täglichen Neuigkeiten zu Bonusangeboten und Slot-Releases.",
              "Exklusive Deals: Profitiere von speziellen Bonusangeboten, die wir für unsere Community ausgehandelt haben.",
              "Detaillierte Reviews: Lies unsere ausführlichen Testberichte zu Online Casinos und Slot-Spielen, bevor du deine Entscheidung triffst."
            ].map((text, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <StyledTypography variant="body1" paragraph>
          Mach GambleBonusWorld zu deinem vertrauenswürdigen Begleiter in der Welt der Online Casinos und Slots. Join uns noch heute und erlebe selbst, warum unsere Community die erste Anlaufstelle für Casino-Enthusiasten ist. Tauche ein in eine Welt voller Spannung, Unterhaltung und lukrativer Möglichkeiten – alles an einem Ort, der dir die besten Voraussetzungen für ein erfolgreiches Spielerlebnis bietet.
        </StyledTypography>
      </StyledPaper>
    </Container>
  );
};

    export default AboutUs;