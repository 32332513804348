import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';

const gradientBackground = 'linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d)';

const BossFightTeaser = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ 
        background: gradientBackground, 
        padding: { xs: 2, sm: 3, md: 4 }, 
        color: 'white', 
        borderRadius: 2,
        overflow: 'hidden'
      }}>
        <Typography 
          variant={isMobile ? "h4" : "h2"} 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{ 
            mb: { xs: 2, sm: 3, md: 4 },
            fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' }
          }}
        >
          Coming Soon ...
        </Typography>
        <Box sx={{ 
          width: '100%', 
          marginBottom: { xs: 2, sm: 3, md: 4 }, 
          display: 'flex', 
          justifyContent: 'center' 
        }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            width="100%"
            style={{ 
              maxWidth: '100%', 
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.5)'
            }}
          >
            <source src="../assets/Bossfight_Video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        <Typography 
          variant={isMobile ? "h5" : isTablet ? "h4" : "h3"} 
          component="h2" 
          gutterBottom 
          align="center"
          sx={{ 
            mb: { xs: 2, sm: 3 },
            fontSize: { xs: '1.25rem', sm: '1.75rem', md: '2.25rem' },
            wordBreak: 'break-word'
          }}
        >
          Boss Fight: Die ultimative Slot-Herausforderung!
        </Typography>
        <Typography 
          variant={isMobile ? "body2" : "body1"} 
          gutterBottom 
          align="center"
          sx={{ 
            mb: { xs: 3, sm: 4 },
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }
          }}
        >
          Hast du das Zeug dazu, den Boss zu besiegen und den Jackpot zu knacken?
        </Typography>

        <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ my: { xs: 2, sm: 3, md: 4 } }}>
          {[
            { title: "Wähle deine Waffe", desc: "Wähle aus einer Vielzahl aufregender Slots, um den Boss herauszufordern" },
            { title: "Bonus Buy Kämpfe", desc: "Stürze dich in spannende Bonus Buy Runden, um dem Boss Schaden zuzufügen" },
            { title: "X-Wert Schaden", desc: "Je höher dein X-Wert, desto mehr Schaden verursachst du!" },
            { title: "Wachsender Jackpot", desc: "Überlebt der Boss, wächst der Jackpot für die nächste Schlacht" },
            { title: "Team up", desc: "Verbünde dich mit anderen Spielern, um harte Bosse zu bezwingen" },
            { title: "Epische Belohnungen", desc: "Besiege den Boss und sichere dir deinen Anteil am massiven Jackpot!" },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper elevation={2} sx={{
                padding: { xs: 1.5, sm: 2 },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: 'white',
              }}>
                <Typography 
                  variant={isMobile ? "subtitle1" : "h6"} 
                  component="h3" 
                  gutterBottom 
                  align="center"
                  sx={{ 
                    mb: 1,
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  align="center"
                  sx={{
                    fontSize: { xs: '0.8rem', sm: '0.875rem', md: '0.9rem' }
                  }}
                >
                  {feature.desc}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default BossFightTeaser;