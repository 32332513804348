import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider, Modal, Box } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import EuroIcon from '@mui/icons-material/Euro';
import AvgIcon from '@mui/icons-material/TrendingUp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GamesIcon from '@mui/icons-material/Games';
import StarIcon from '@mui/icons-material/Star';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import SpeedIcon from '@mui/icons-material/Speed';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #1a1a1a;
    font-family: 'Roboto', sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  padding: 40px;
  text-align: center;
  background-color: #2a2a2a;
  color: #e0e0e0;
  border-radius: 15px;
  width: 95%;
  margin: 20px auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #7526cd;
  font-size: 2.5em;
  margin-bottom: 30px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
  flex-wrap: wrap;
`;

const Stat = styled.div`
  text-align: center;
  background-color: #3a3a3a;
  padding: 20px;
  border-radius: 10px;
  min-width: 150px;
  margin: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatLabel = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #7526cd;
`;

const StatValue = styled.div`
  font-size: 1.2em;
  color: white;
`;

const Message = styled.p`
  color: ${props => (props.success ? '#4caf50' : '#f44336')};
  font-weight: bold;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: #7526cd;
    }
    &:hover fieldset {
      border-color: #9b4de3;
    }
    &.Mui-focused fieldset {
      border-color: #7526cd;
    }
  }
  & .MuiInputLabel-root {
    color: #e0e0e0;
  }
  & .MuiInputBase-input {
    color: black;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #7526cd;
    color: #ffffff;
    &:hover {
      background-color: #9b4de3;
    }
  }
`;

const StyledTableContainer = styled(TableContainer)`
  && {
    background-color: #3a3a3a;
    border-radius: 10px;
    margin-top: 30px;
  }
`;

const StyledTableCell = styled(TableCell)`
  && {
    color: #e0e0e0;
    border-bottom: 1px solid #4a4a4a;
  }
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #333333;
  }
  &:last-child td, &:last-child th {
    border: 0;
  }
`;

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 24px;
`;

const ScrollableList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BonusHuntTippspiel = () => {
  const [games, setGames] = useState([]);
  const [viewerName, setViewerName] = useState('');
  const [predictedTotal, setPredictedTotal] = useState('');
  const [startValue, setStartValue] = useState(0);
  const [avgBet, setAvgBet] = useState(null);
  const [bestSlot, setBestSlot] = useState(null);
  const [nextSlot, setNextSlot] = useState(null);
  const [totalGames, setTotalGames] = useState(0);
  const [completedGames, setCompletedGames] = useState(0);
  const [avgXFactor, setAvgXFactor] = useState(null);
  const [currentXFactor, setCurrentXFactor] = useState(null);
  const [balanceBeforeOpening, setBalanceBeforeOpening] = useState(0);
  const [currentBonushuntId, setCurrentBonushuntId] = useState(null);
  const [hasSubmittedTip, setHasSubmittedTip] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [bonushuntStatus, setBonushuntStatus] = useState('inactive');
  const [openModal, setOpenModal] = useState(false);
  const [evaluationResults, setEvaluationResults] = useState([]);
  const [finalResult, setFinalResult] = useState(0);
  const [avgXValue, setAvgXValue] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCurrentBonushuntId = async () => {
      try {
        const response = await fetch(`${API_URL}/api/current-bonushunt-id`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCurrentBonushuntId(data.aktuelle_id);
      } catch (error) {
        console.error('Fetching current bonushunt ID failed:', error);
      }
    };

    fetchCurrentBonushuntId();
  }, [API_URL]);

  useEffect(() => {
    const fetchBonushuntStatus = async () => {
      if (!currentBonushuntId) return;
      try {
        const response = await fetch(`${API_URL}/api/bonushunt-status/${currentBonushuntId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBonushuntStatus(data.status);
      } catch (error) {
        console.error('Fetching bonushunt status failed:', error);
      }
    };

    fetchBonushuntStatus();
  }, [currentBonushuntId, API_URL]);

  useEffect(() => {
    const fetchGames = async () => {
      if (!currentBonushuntId) return;

      try {
        const response = await fetch(`${API_URL}/api/games/${currentBonushuntId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGames(data);

        const completed = data.filter(game => game.result).length;
        setCompletedGames(completed);
        setTotalGames(data.length);

        const avgBetValue = calculateAverageBet(data);
        setAvgBet(avgBetValue.toFixed(2));

        const totalXFactor = data.reduce((acc, game) => {
          if (game.result && game.bet) {
            return acc + game.result / game.bet;
          }
          return acc;
        }, 0);
        
        const avgXFactorValue = completed > 0 ? (totalXFactor / completed).toFixed(2) : '0.00';
        setCurrentXFactor(avgXFactorValue);
      } catch (error) {
        console.error('Fetching games failed:', error);
      }
    };

    if (currentBonushuntId) {
      fetchGames();
    }
  }, [currentBonushuntId, API_URL]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${API_URL}/api/stats`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
    
        setBestSlot(data.bestSlot);
        setStartValue(data.startValue);
        setBalanceBeforeOpening(data.balanceBeforeOpening); // Add this line
    
        const avgXFactorValue = parseFloat(data.avgXFactor);
        setAvgXFactor(!isNaN(avgXFactorValue) ? avgXFactorValue.toFixed(2) : '0.00');
      } catch (error) {
        console.error('Fetching stats failed:', error);
      }
    };
    

    fetchStats();
  }, [games]);

  const calculateAverageBet = (games) => {
    if (games.length === 0) return 0;
    const totalBet = games.reduce((sum, game) => sum + game.bet, 0);
    return totalBet / games.length;
  };

  const checkTipSubmission = async (viewerNameToCheck) => {
    if (!viewerNameToCheck || !currentBonushuntId) return false;
    try {
      const response = await fetch(`${API_URL}/api/tips/check?viewerName=${viewerNameToCheck.toLowerCase()}&bonushunt_id=${currentBonushuntId}`);
      const data = await response.json();
      return data.hasSubmitted;
    } catch (error) {
      console.error('Checking tip submission failed:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasSubmitted = await checkTipSubmission(viewerName);
    if (hasSubmitted) {
      setHasSubmittedTip(true);
      setSubmissionMessage('Sie haben bereits einen Tipp abgegeben.');
      setIsSuccessMessage(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/tips`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ viewerName: viewerName.toLowerCase(), predictedTotal, bonushunt_id: currentBonushuntId }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setViewerName('');
      setPredictedTotal('');
      setHasSubmittedTip(true);
      setSubmissionMessage('Tipp erfolgreich eingereicht.');
      setIsSuccessMessage(true);
    } catch (error) {
      console.error('Submitting tip failed:', error);
    }
  };

  const determineNextSlot = (games) => {
    let lastCompletedIndex = -1;

    for (let i = 0; i < games.length; i++) {
      if (games[i].result) {
        lastCompletedIndex = i;
      }
    }

    for (let i = lastCompletedIndex + 1; i < games.length; i++) {
      if (!games[i].result) {
        return games[i];
      }
    }

    return null;
  };

  useEffect(() => {
    setNextSlot(determineNextSlot(games));
  }, [games]);

  const handleOpenModal = async () => {
    try {
      const response = await fetch(`${API_URL}/api/bonushunt-results/${currentBonushuntId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEvaluationResults(data.results);
      setFinalResult(parseFloat(data.finalResult).toFixed(2));
      setAvgXValue(data.avgXValue);
      setStartValue(data.startValue);
      setBalanceBeforeOpening(parseFloat(data.balanceBeforeOpening).toFixed(2));
      setOpenModal(true);
    } catch (error) {
      console.error('Fetching evaluation results failed:', error);
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <GlobalStyle />
      <StyledContainer component={Paper}>
        <Title>
          <CasinoIcon style={{ marginRight: '10px', fontSize: '1.2em' }} />
          Bonus Hunt Tippspiel
        </Title>
        {bonushuntStatus === 'active' ? (
          !hasSubmittedTip ? (
            <StyledForm onSubmit={handleSubmit}>
              <StyledTextField
                label="Name"
                variant="outlined"
                value={viewerName}
                onChange={(e) => setViewerName(e.target.value)}
                required
              />
              <StyledTextField
                label="Tipp in €"
                variant="outlined"
                type="number"
                value={predictedTotal}
                onChange={(e) => setPredictedTotal(e.target.value)}
                required
              />
              <StyledButton variant="contained" type="submit">
                Tipp abgeben
              </StyledButton>
            </StyledForm>
          ) : (
            <Message success={isSuccessMessage}>{submissionMessage}</Message>
          )
        ) : bonushuntStatus === 'abgeschlossen' ? (
          <>
            <Message>Bonushunt abgeschlossen</Message>
            <Button variant="contained" onClick={handleOpenModal}>
              Auswertung anzeigen
            </Button>
            <Modal open={openModal} onClose={handleCloseModal}>
              <ModalBox>
                <h2>Bonushunt Auswertung</h2>
                <p>StartBalance: {startValue}€</p>
                <p>Rest-Balance: {balanceBeforeOpening}€</p>
                <p>Endergebnis: {finalResult}€</p>
                <p>Ø X-Wert: {avgXValue}</p>
                <ScrollableList>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Spieler</TableCell>
                          <TableCell>Getippte Summe</TableCell>
                          <TableCell>Abweichung</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {evaluationResults.map((result, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell>{result.viewerName}</TableCell>
                            <TableCell>{parseFloat(result.predictedTotal).toFixed(2)}€</TableCell>
                            <TableCell>{parseFloat(result.difference).toFixed(2)}€</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollableList>
              </ModalBox>
            </Modal>
          </>
        ) : (
          <Message>Tippspiel derzeit nicht aktiv</Message>
        )}
        <Divider style={{ backgroundColor: '#4a4a4a', margin: '30px 0' }} />
        <StatsContainer>
          <Stat>
            <StatLabel><EuroIcon style={{ marginRight: '5px' }} /> START</StatLabel>
            <StatValue>{startValue}€</StatValue>
          </Stat>
          <Stat>
            <StatLabel>Rest-Balante</StatLabel>
            <StatValue>{balanceBeforeOpening}€</StatValue>
          </Stat>
          <Stat>
            <StatLabel><TrendingUpIcon style={{ marginRight: '5px' }} /> Ø Einsatz</StatLabel>
            <StatValue>{avgBet}€</StatValue>
          </Stat>
          <Stat>
            <StatLabel><TrendingUpIcon style={{ marginRight: '5px' }} /> Ø X-Wert</StatLabel>
            <StatValue>{avgXFactor}X</StatValue>
          </Stat>
          <Stat>
            <StatLabel><GamesIcon style={{ marginRight: '5px' }} /> Spiel</StatLabel>
            <StatValue>{completedGames}/{totalGames}</StatValue>
          </Stat>
        </StatsContainer>
        <Divider style={{ backgroundColor: '#4a4a4a', margin: '30px 0' }} />
        <StatsContainer>
          <Stat>
            <StatLabel><StarIcon style={{ marginRight: '5px' }} /> Best Slot</StatLabel>
            <StatValue>{bestSlot ? `${bestSlot.name} | ${bestSlot.result}€` : 'N/A'}</StatValue>
          </Stat>
          <Stat>
            <StatLabel><NextPlanIcon style={{ marginRight: '5px' }} /> Next Slot</StatLabel>
            <StatValue>{nextSlot ? nextSlot.name : 'N/A'}</StatValue>
          </Stat>
          <Stat>
            <StatLabel><SpeedIcon style={{ marginRight: '5px' }} /> Aktueller X-Wert</StatLabel>
            <StatValue>{currentXFactor}X</StatValue>
          </Stat>
        </StatsContainer>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#2a2a2a' }}>Spielname</StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#2a2a2a' }}>Einsatz</StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#2a2a2a' }}>Ergebnis</StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#2a2a2a' }}>X-Wert</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.length > 0 ? (
                games.map(game => (
                  <StyledTableRow key={game.id}>
                    <StyledTableCell>{game.name}</StyledTableCell>
                    <StyledTableCell>{game.bet} €</StyledTableCell>
                    <StyledTableCell>
                      {game.result ? `${game.result} €` : ''}
                    </StyledTableCell>
                    <StyledTableCell>
                      {game.result && game.bet ? `${(game.result / game.bet).toFixed(2)}x` : ''}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>Keine Spiele verfügbar</StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
};

export default BonusHuntTippspiel;
