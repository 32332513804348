import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';

// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    overflow: hidden;
  }  
`;


const Team = styled.div`
  padding: 10px;
  background-color: rgba(43, 43, 43, 0.61);
  border-radius: 16px;
  color: white;
  padding-left: 70px;
  position: relative;
  padding-bottom: 25px;
`;

const TeamDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px;
  box-sizing: border-box;
  padding: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TeamNr = styled.span`
  font-size: 30px;
  font-weight: 600;
`;

const MoneyResultSum = styled.span`
  font-weight: bold;
  font-size: 28px;
  color: gold;
  margin-top: 5px;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: end;
  margin-right: 15px;
`;


const ImgWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const PlayerName = styled.span`
  font-size: 22px;
  font-weight: 900;
  white-space: nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(to right, black 70%, transparent 100%);
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GameName = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const PlayerValue = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: red;
`;

const Separator = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  .text {
    margin: 5px 10px;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 1px;
    color: rgb(255, 68, 43);
  }

  .line {
    height: 60px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-bottom: 10px;
  }

  .line2 {
    height: 60px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-top: 10px;
  }
`;

const DuelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: calc(100vh - 10px);
  box-sizing: border-box;
  overflow-y: hidden;
`;

const CompetitionDisplay = styled.div`
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  justify-content: space-between;
  transform: scale(0.9);
`;

const TeamWrapper = styled.div`
  padding: 10px;
  background-color: rgba(43, 43, 43, 0.61);
  border-radius: 16px;
  color: white;
  position: relative;
  width: 320px;
`;

const PlayerCard = styled.div`
  background-color: rgb(165, 167, 172);
  color: black;
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

const ViewerViewActualDuel = () => {
  const [currentDuel, setCurrentDuel] = useState(null);
  const [error, setError] = useState(null);


  const fetchCurrentDuel = async () => {
    try {
      const response = await axios.get(`${API_URL}/currentDuel`, {
        params: { tournamentType: 'teamDuel' }
      });
      setCurrentDuel(response.data);
      setError(null);
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Error fetching current duel');
      setCurrentDuel(null);
    }
  };

  useEffect(() => {
    fetchCurrentDuel();
    const interval = setInterval(fetchCurrentDuel, 5000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!currentDuel) {
    return <div>Loading...</div>;
  }

  const renderPlayerCard = (player) => {
    if (!player) return null;

    const xFactor = player.buyValue > 0 ? (parseFloat(player.value) / parseFloat(player.buyValue)).toFixed(2) : '0';
  
    return (
      <PlayerCard>
        <ImgWrapper>
          {player.profileImageUrl ? (
            <img src={player.profileImageUrl} alt={player.username} className="profileImg" />
          ) : (
            <i className="fas fa-user"></i>
          )}
        </ImgWrapper>
        <Details>
          <PlayerName>{player.username}</PlayerName>
          <InfoWrapper>
            <GameName>{player.game}</GameName>
            <PlayerValue>
              {xFactor}x
              <br />
              <span style={{ fontSize: '16px', color: 'grey' }}>
                {parseFloat(player.value).toFixed(2)} €
              </span>
            </PlayerValue>
          </InfoWrapper>
        </Details>
      </PlayerCard>
    );
  };

  const renderTeam = (team) => {
    return (
      <TeamWrapper>
        {team.map((player, index) => renderPlayerCard(player))}
      </TeamWrapper>
    );
  };

  return (
    <>
      <GlobalStyle />
      <DuelWrapper>
        <CompetitionDisplay>
          {renderTeam(currentDuel.team1)}
          <Separator>
            <div className="line"></div>
            <span className="text">VS</span>
            <div className="line2"></div>
          </Separator>
          {renderTeam(currentDuel.team2)}
        </CompetitionDisplay>
      </DuelWrapper>
    </>
  );
};

export default ViewerViewActualDuel;