// CombinedContainer.js
import React from 'react';
import styled from 'styled-components';
import HeroSection from './HeroSection';
import BonusEntry from './BonusEntry';
import Filter from './Filter';
import { useMediaQuery } from '@mui/material';
import AboutUs from './AboutUs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BonusList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;
`;

const CombinedContainer = ({ bonusEntries, filters, setFilters }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Container>
      <HeroSection />
      {isMobile && <Filter filters={filters} setFilters={setFilters} />}
      <BonusList>
        {bonusEntries.map((entry, index) => (
          <BonusEntry key={index} {...entry} />
        ))}
      </BonusList>
      <AboutUs />
    </Container>
  );
};

export default CombinedContainer;
