import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Typography, Divider, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)`
  background-color: #2c2c2c;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const StatContainer = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 0 40px;
`;

const Stat = styled('div')`
  flex: 1;
  text-align: center;
  margin: 0 30px;
`;

const StatLabel = styled(Typography)`
  font-weight: bold;
`;

const GameList = styled(TableContainer)`
  max-height: 150px;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const ScrollingTableBody = styled(TableBody)`
  position: absolute;
  width: 100%;
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
`;

const StyledTableCell = styled(TableCell)`
  color: white;
  padding: 16px;
  word-wrap: break-word;
`;

const StickyTableCell = styled(TableCell)`
  color: white;
  background-color: #1c1c1c;
  top: 0;
  position: sticky;
  z-index: 1;
  word-wrap: break-word;
`;

const API_URL = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;

const SlotStats = () => {
  const [data, setData] = useState({
    start: 0,
    avgBet: 0,
    xFactor: 0,
    currentGame: 0,
    bestSlot: { name: '', bet: 0, result: 0 },
    nextSlot: { name: '', bet: 0 },
    games: [],
    balanceBeforeOpening: 0, // Add this line
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);
  const animationRef = useRef(null);

  const fetchInitialData = async () => {
    try {
      const statsResponse = await fetch(`${API_URL}/api/stats`);
      const stats = await statsResponse.json();
      const currentBonushuntIdResponse = await fetch(`${API_URL}/api/current-bonushunt-id`);
      const { aktuelle_id } = await currentBonushuntIdResponse.json();
      const gamesResponse = await fetch(`${API_URL}/api/games/${aktuelle_id}`);
      const games = await gamesResponse.json();
      const avgBet = calculateAverageBet(games);
  
      setData({
        start: stats.startValue,
        avgBet: avgBet.toFixed(2),
        xFactor: stats.avgXFactor,
        currentGame: games.filter(game => game.result).length,
        bestSlot: stats.bestSlot,
        nextSlot: determineNextSlot(games),
        games,
        balanceBeforeOpening: stats.balanceBeforeOpening, // Add this line
      });
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
  

  useEffect(() => {
    fetchInitialData();
  
    const ws = new WebSocket(`wss://${WS_URL}?clientType=slotstats`);
  
    ws.onopen = () => {
      console.log('WebSocket-Verbindung hergestellt');
    };
  
    ws.onmessage = () => {
      fetchInitialData();
      console.log("NEU GELADEN");
    };
  
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };
  
    return () => {
      ws.close();
    };
  }, []);
  

  const animate = useCallback(() => {
    const scrollHeight = scrollRef.current?.scrollHeight || 0;
    const visibleHeight = 150; // GameList max-height
    const totalScrollDistance = scrollHeight - visibleHeight;

    const scrollSpeed = 1; // Increased speed for smoother appearance
    const scrollDuration = totalScrollDistance / scrollSpeed * 100;

    let startTime;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;

      const newScrollPosition = (elapsedTime % scrollDuration) / scrollDuration * totalScrollDistance;
      setScrollPosition(newScrollPosition);

      animationRef.current = requestAnimationFrame(step);
    };

    animationRef.current = requestAnimationFrame(step);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const cleanup = animate();
    return cleanup;
  }, [animate, data.games]);

  const calculateAverageBet = (games) => {
    if (games.length === 0) return 0;
    const totalBet = games.reduce((sum, game) => sum + parseFloat(game.bet), 0);
    return totalBet / games.length;
  };

  const determineNextSlot = (games) => {
    const unfinishedGame = games.find(game => !game.result);
    return unfinishedGame || { name: 'N/A', bet: 0 };
  };
  
  const determineBestSlot = (games) => {
    return games.reduce((best, current) =>
      (current.result && (!best.result || current.result > best.result)) ? current : best
    , { name: 'N/A', bet: 0, result: 0 });
  };

  return (
    <StyledContainer>
      <StatContainer>
        <Stat>
          <StatLabel>START</StatLabel>
          <Typography>{data.start}€</Typography>
        </Stat>
        <Stat>
          <StatLabel>Rest-Balante</StatLabel>
          <Typography>{data.balanceBeforeOpening}€</Typography>
        </Stat>
        <Stat>
          <StatLabel>Ø EINSATZ</StatLabel>
          <Typography>{data.avgBet}€</Typography>
        </Stat>
        <Stat>
          <StatLabel>X-FAKTOR</StatLabel>
          <Typography>{data.xFactor}X</Typography>
        </Stat>
        <Stat>
          <StatLabel>SPIEL</StatLabel>
          <Typography>{data.currentGame}/{data.games.length}</Typography>
        </Stat>
      </StatContainer>
      <Divider style={{ backgroundColor: 'white', marginBottom: '20px' }} />
      <StatContainer>
        <Stat>
          <StatLabel>Best Slot:</StatLabel>
          <Typography>{data.bestSlot?.name || 'N/A'} | {data.bestSlot?.result ? `${data.bestSlot.result}€` : ''}</Typography>
        </Stat>
        <Stat>
          <StatLabel>Next Slot:</StatLabel>
          <Typography>{data.nextSlot?.name || 'N/A'} | {data.nextSlot?.bet ? `${data.nextSlot.bet}€` : ''}</Typography>
        </Stat>
      </StatContainer>
      <Divider style={{ backgroundColor: 'white', marginBottom: '20px' }} />
      <GameList component={Paper} style={{ backgroundColor: '#424242' }}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StickyTableCell style={{ width: '55%' }}>Spielname</StickyTableCell>
              <StickyTableCell style={{ width: '15%' }}>Einsatz</StickyTableCell>
              <StickyTableCell style={{ width: '15%' }}>Ergebnis</StickyTableCell>
              <StickyTableCell style={{ width: '15%' }}>X-Wert</StickyTableCell>
            </TableRow>
          </TableHead>
        </StyledTable>
        <div style={{ position: 'relative', overflow: 'hidden', height: '150px' }}>
          <ScrollingTableBody
            ref={scrollRef}
            style={{ transform: `translateY(${-scrollPosition}px)` }}
          >
            <StyledTable>
              <TableBody>
                {data.games.length > 0 ? (
                  data.games.map((game, index) => (
                    <TableRow key={game.id} style={{ backgroundColor: index % 2 === 0 ? '#3c3c3c' : '#2c2c2c' }}>
                      <StyledTableCell style={{ width: '55%' }}>{index + 1}. {game.name || 'N/A'}</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>{game.bet}€</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>{game.result ? `${game.result}€` : ''}</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>{game.result && game.bet ? (game.result / game.bet).toFixed(2) + 'x' : ''}</StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={4}>Keine Spiele verfügbar</StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </ScrollingTableBody>
        </div>
      </GameList>
    </StyledContainer>
  );
};

export default SlotStats;