import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';

// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    overflow: hidden;
  }  
`;

const TournamentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  height: calc(100vh - 10px);
  box-sizing: border-box;
  overflow-y: hidden;
`;

const WinnersDisplayAnimation = keyframes`
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.1); }
  100% { transform: translate(-50%, -50%) scale(1); }
`;

const WinnersDisplay = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 20px;
  color: white;
  text-align: center;
  animation: ${WinnersDisplayAnimation} 2s infinite;
  width: 25%;
  height: auto;
`;

const WinnerHeader = styled.h2`
  margin-bottom: 20px;
`;

const WinnerPlayerCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const WinnerImgWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const WinnerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const WinnerPlayerName = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const TournamentSettings = ({ prizes }) => {
  return (
    <div style={{ position: 'absolute', top: 10, right: 10, background: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
      <h3>Preisgelder:</h3>
      <ul>
        {prizes.map((prize, index) => (
          <li key={index}>
            Platz {index + 1}: {prize.value} {prize.currency}
          </li>
        ))}
      </ul>
    </div>
  );
};

const RoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 33%;
  height: 100%;
  transform: scale(0.9); /* Apply the zoom effect */
  transform-origin: top left; /* Make sure the zoom starts from the top left */
`;

const CompetitionDisplay = styled.div`
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  justify-content: space-between;
  transform: scale(0.7);
`;

const Team = styled.div`
  padding: 10px;
  background-color: rgba(43, 43, 43, 0.61);
  border-radius: 16px;
  color: white;
  padding-left: 70px;
  position: relative;
  padding-bottom: 25px;
`;

const TeamDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px;
  box-sizing: border-box;
  padding: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TeamNr = styled.span`
  font-size: 30px;
  font-weight: 600;
`;

const TeamFollowerNumber = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

const TeamFollowerVal = styled.span`
  font-size: 20px;
`;

const MoneyResultSum = styled.span`
  font-weight: bold;
  font-size: 28px;
  color: gold;
  margin-top: 5px;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: end;
  margin-right: 15px;
`;

const PlayerCard = styled.div`
  background-color: rgb(165, 167, 172);
  color: black;
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 300px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
`;

const ImgWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const PlayerName = styled.span`
  font-size: 22px;
  font-weight: 900;
  white-space: nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(to right, black 70%, transparent 100%);
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GameName = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const PlayerValue = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: red;
`;

const EndergebnisValue = styled.span`
  font-size: 14px;
  color: grey;
  margin-right: 10px;
`;

const Separator = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  .text {
    margin: 5px 10px;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 1px;
    color: rgb(255, 68, 43);
  }

  .line {
    height: 60px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-bottom: 10px;
  }

  .line2 {
    height: 60px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-top: 10px;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

const ViewerView = () => {
  const [teamDuelState, setTeamDuelState] = useState({ round: 1, teams: Array.from({ length: 4 }, () => [null, null]) });
  const [prizes, setPrizes] = useState([]);
  const [allRounds, setAllRounds] = useState([
    Array.from({ length: 8 }, () => [null, null]),  // Runde 1
    Array.from({ length: 4 }, () => [null, null]),  // Runde 2
    Array.from({ length: 2 }, () => [null, null]),  // Runde 3
    Array.from({ length: 1 }, () => [null, null])   // Runde 4
  ]);
  const [winners, setWinners] = useState([]);

  const fetchData = async () => {
    try {
      const teamDuelStateRes = await axios.get(`${API_URL}/teamDuelState`);
      const settingsRes = await axios.get(`${API_URL}/tournamentSettings`);
      const allRoundsRes = await axios.get(`${API_URL}/allRounds`);

      setTeamDuelState(teamDuelStateRes.data);
      setPrizes(settingsRes.data.prizes || []); // Default to empty array if not available
      setAllRounds(allRoundsRes.data);

      if (teamDuelStateRes.data.winners && teamDuelStateRes.data.winners.length > 0) {
        setWinners(teamDuelStateRes.data.winners);
      }

      console.log('Team Duel State:', teamDuelStateRes.data);
      console.log('Tournament Settings:', settingsRes.data);
      console.log('All Rounds:', allRoundsRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const renderPlayerCard = (player) => {
    const xFactor = player ? (player.buyValue > 0 ? (parseFloat(player.value) / parseFloat(player.buyValue)).toFixed(2) : '0') : '0';
  
    return (
      <PlayerCard className={player ? 'playerCard' : 'skeleton'}>
        <ImgWrapper>
          {player ? (
            <img src={player.profileImageUrl} alt={player.username} className="profileImg" />
          ) : (
            <i className="fas fa-question"></i>
          )}
        </ImgWrapper>
        <Details>
          <PlayerName>{player ? player.username : 'Empty'}</PlayerName>
          <InfoWrapper>
            <GameName>{player ? player.game : 'Slot'}</GameName>
            <PlayerValue><span style={{ fontSize: '14px', color: 'grey', marginRight: '10px' }}>({player ? `${parseFloat(player.value).toFixed(2)}` : ''} €)</span>{xFactor}x</PlayerValue>
            
          </InfoWrapper>
          
        </Details>
      </PlayerCard>
    );
  };

  const renderTeam = (team, teamIndex, opponentTeam, opponentTeamIndex) => {
    const calculateTotalXFactor = (team) =>
      team.reduce((acc, player) => acc + (player ? (player.buyValue > 0 ? parseFloat(player.value) / parseFloat(player.buyValue) : 0) : 0), 0).toFixed(2);

    return (
      <CompetitionDisplay key={teamIndex}>
        <Team>
          <TeamDisplay>
            <TeamNr>#{teamIndex + 1}</TeamNr>
          </TeamDisplay>
          {renderPlayerCard(team[0])}
          {renderPlayerCard(team[1])}
          <MoneyResultSum>{calculateTotalXFactor(team)}x</MoneyResultSum>
        </Team>
        <Separator>
          <div className="line"></div>
          <span className="text">VS</span>
          <div className="line2"></div>
        </Separator>
        <Team>
          <TeamDisplay>
            <TeamNr>#{teamIndex + 2}</TeamNr>
          </TeamDisplay>
          {renderPlayerCard(opponentTeam[0])}
          {renderPlayerCard(opponentTeam[1])}
          <MoneyResultSum>{calculateTotalXFactor(opponentTeam)}x</MoneyResultSum>
        </Team>
      </CompetitionDisplay>
    );
  };

  const renderRound = (teams, roundSize) => {
    const paddedTeams = teams.concat(Array.from({ length: roundSize - teams.length }, () => [null, null]));
    return (
      <RoundWrapper>
        {paddedTeams.map((team, index) =>
          index % 2 === 0 && paddedTeams[index + 1] ? renderTeam(team, index, paddedTeams[index + 1], index + 1) : null
        )}
      </RoundWrapper>
    );
  };

  return (
    <>
      <GlobalStyle />
      <TournamentWrapper>
        {allRounds.map((roundTeams, roundIndex) => renderRound(roundTeams, 8 / Math.pow(2, roundIndex)))} {/* Render each round */}
        <TournamentSettings prizes={prizes} />
        {winners.length > 0 && (
          <WinnersDisplay>
            <WinnerHeader>Final Winners</WinnerHeader>
            {winners.map((winner, index) => (
              <div key={index}>
                {winner.map((player, i) => (
                  <WinnerPlayerCard key={i}>
                    <WinnerImgWrapper>
                      {player.profileImageUrl ? (
                        <img src={player.profileImageUrl} alt={player.username} />
                      ) : (
                        <i className="fas fa-question"></i>
                      )}
                    </WinnerImgWrapper>
                    <WinnerDetails>
                      <WinnerPlayerName>{player.username}</WinnerPlayerName>
                    </WinnerDetails>
                  </WinnerPlayerCard>
                ))}
              </div>
            ))}
          </WinnersDisplay>
        )}
      </TournamentWrapper>
    </>
  );
};

export default ViewerView;
