import React from 'react';
import styled from 'styled-components';
import SocialLinks from './SocialLinks';

const CommunityContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #7526cd;
  text-align: center;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #7526cd;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5e1fa6;
  }
`;

const CommunityContact = () => {
  return (
    <CommunityContactContainer>
      <Title>Community & Kontakt</Title>

      <Section>
        <SectionTitle>Unsere Community</SectionTitle>
        <Text>
          Willkommen in der BonusGambleWorld-Community! Hier treffen sich Gleichgesinnte, 
          um Erfahrungen auszutauschen, gemeinsam zu slotten und Spaß zu haben. 
          Unser Discord-Server ist der zentrale Treffpunkt für alle Community-Aktivitäten.
        </Text>
        <Text>
          Was dich erwartet:
          <ul>
            <li>Gemeinsame Slot-Sessions und Turniere</li>
            <li>Diskussionen über Strategien und neue Spiele</li>
            <li>Exklusive Tipps und Bonus-Informationen</li>
            <li>Freundlicher Austausch in einer positiven Atmosphäre</li>
            <li>Weitere Giveaways</li>
          </ul>
        </Text>
        <Button href="https://discord.gg/F7PMMzBz4d" target="_blank" rel="noopener noreferrer">
          Join unserem Discord
        </Button>
      </Section>

      <Section>
        <SectionTitle>Kontakt</SectionTitle>
        <Text>
          Hast du Fragen, Anregungen oder möchtest du immer auf dem Laufenden bleiben? 
          Unser Telegram-Kanal ist deine direkte Verbindung zum BonusGambleWorld-Team.
        </Text>
        <Text>
          Über unseren Telegram-Kanal erhältst du:
          <ul>
            <li>Schnelle Antworten auf deine Fragen</li>
            <li>Aktuelle News und exklusive Angebote</li>
            <li>Direkte Updates zu neuen Boni und Promotionen</li>
            <li>Die Möglichkeit, dich mit anderen Mitgliedern zu vernetzen</li>
          </ul>
        </Text>
        <Button href="https://t.me/+n6YlV6xvzCAyYmVi" target="_blank" rel="noopener noreferrer">
          Unserem Telegram beitreten
        </Button>
      </Section>

      <SocialLinks />
    </CommunityContactContainer>
  );
};

export default CommunityContact;