import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import EditModal from './EditBonusEntryModal';
import EditHeroCasiModal from './EditHeroCasiModal';  // Import the new modal
import axios from 'axios';
import { Button as MUIButton, Modal, Typography, Box } from '@mui/material';

const Container = styled.div`
  padding: 20px;
  text-align: left;
  position: relative;
`;

const StyledButton = styled.button`
  padding: 10px;
  font-size: 1em;
  background-color: ${(props) => (props.color === 'red' ? '#e74c3c' : '#3498db')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${(props) => (props.color === 'red' ? '#c0392b' : '#2980b9')};
  }
`;


const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 50%;
  position: relative;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 1.2em;
  color: #333;
`;

const MoveButton = styled.button`
  padding: 5px 10px;
  font-size: 0.8em;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: #1c5980;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled(StyledButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`;

const ManageEntries = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);  // State for Hero modal

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${API_URL}/api/bonus-entries`)
      .then(response => response.json())
      .then(data => setEntries(Array.isArray(data) ? data : []))
      .catch(error => console.error('Fetch error:', error));
  }, [API_URL]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentEntry(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const updatedEntries = currentEntry.id != null
      ? entries.map(entry => entry.id === currentEntry.id ? currentEntry : entry)
      : [...entries, { ...currentEntry, id: entries.length > 0 ? Math.max(...entries.map(entry => entry.id)) + 1 : 0 }];
    setEntries(updatedEntries);

    const token = localStorage.getItem('token');

    fetch(`${API_URL}/api/bonus-entries`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(updatedEntries),
    })
      .then(response => response.json())
      .then(data => console.log(data.message))
      .catch(error => console.error('Fetch error:', error));
    setCurrentEntry(null);
    setIsModalOpen(false);
  };

  const handleHeroSave = () => {
    const token = localStorage.getItem('token');
  
    fetch(`${API_URL}/api/hero-entry`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(currentEntry), // Send only the current hero entry
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => { throw new Error(text) });
        }
        return response.json();
      })
      .then(data => console.log(data.message))
      .catch(error => console.error('Fetch error:', error));
    setCurrentEntry(null);
    setIsHeroModalOpen(false);
  };

  const handleEdit = (entry) => {
    setCurrentEntry(entry);
    setIsNewEntry(false);
    setIsModalOpen(true);
  };

  const handleAddNew = () => {
    setCurrentEntry({ logo: '', title: '', tag: '', link: '', bonusType: '', bonusAmount: 0, maxBonus: 0, maxBet: 0, wager: 0, extraInfo: { highlights: [], paymentMethods: [] }, stats: [] });
    setIsNewEntry(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentEntry(null);
    setIsModalOpen(false);
  };

  const handleHeroModalClose = () => {
    setCurrentEntry(null);
    setIsHeroModalOpen(false);
  };

  const handleLogoUpload = async (event) => {
    const logoFile = event.target.files[0];
    const formData = new FormData();
    formData.append('logo', logoFile);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/api/upload-logo`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'x-access-token': token
        },
      });
      const logoUrl = response.data.filePath;
      setCurrentEntry(prevState => ({
        ...prevState,
        logo: logoUrl,
      }));
      console.log('Logo uploaded:', logoUrl);
    } catch (error) {
      console.error('Error uploading logo:', error);
    }
  };

  const moveEntryUp = (index) => {
    if (index === 0) return;
    const newEntries = Array.from(entries);
    const [movedEntry] = newEntries.splice(index, 1);
    newEntries.splice(index - 1, 0, movedEntry);
    setEntries(newEntries);
    saveEntries(newEntries);
  };

  const moveEntryDown = (index) => {
    if (index === entries.length - 1) return;
    const newEntries = Array.from(entries);
    const [movedEntry] = newEntries.splice(index, 1);
    newEntries.splice(index + 1, 0, movedEntry);
    setEntries(newEntries);
    saveEntries(newEntries);
  };

  const saveEntries = (updatedEntries) => {
    const token = localStorage.getItem('token');

    fetch(`${API_URL}/api/bonus-entries`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(updatedEntries),
    })
    .then(response => response.json())
    .then(data => console.log(data.message))
    .catch(error => console.error('Fetch error:', error));
  };

  const handleDelete = (entry) => {
    setEntryToDelete(entry);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedEntries = entries.filter(entry => entry.id !== entryToDelete.id);
    setEntries(updatedEntries);
    saveEntries(updatedEntries);
    setDeleteModalOpen(false);
  };

  const handleHeroEdit = (entry) => {
    setCurrentEntry(entry);
    setIsNewEntry(false);
    setIsHeroModalOpen(true);
  };

  const handleAddNewHero = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/heroCasi`);
      const heroData = response.data;
      setCurrentEntry(heroData);
    } catch (error) {
      console.error('Error fetching heroCasi data:', error);
      setCurrentEntry({ 
        logo: '', title: '', tag: '', link: '', bonusType: '', bonusAmount: 0, 
        maxBonus: 0, maxBet: 0, wager: 0, extraInfo: { highlights: [], paymentMethods: [] }, 
        stats: [], backgroundImage: '', backgroundSize: 'cover', backgroundColor: '', HeroSectionTitle: '', HeroFeatures: [] 
      });
    }
    setIsNewEntry(true);
    setIsHeroModalOpen(true);
  };

  return (
    <Container>
      <h2>Manage Bonus Entries</h2>
      <StyledButton onClick={handleAddNew}>Add New Entry</StyledButton>
      <StyledButton onClick={handleAddNewHero}>Hero Casi Ändern</StyledButton>
      <input type="file" accept="image/*" onChange={handleLogoUpload} style={{ marginLeft: '10px' }} />
      <h3>Existing Entries</h3>
      <CardContainer>
        {Array.isArray(entries) && entries.map((entry, index) => (
          <Card key={entry.id}>
            <Logo src={entry.logo} alt={entry.title} />
            <Title>{entry.title}</Title>
            <div>
              <MoveButton onClick={() => moveEntryUp(index)} disabled={index === 0}>Up</MoveButton>
              <MoveButton onClick={() => moveEntryDown(index)} disabled={index === entries.length - 1}>Down</MoveButton>
            </div>
            <StyledButton onClick={() => handleEdit(entry)}>Edit</StyledButton>
            <DeleteButton onClick={() => handleDelete(entry)}>Delete</DeleteButton>
          </Card>
        ))}
      </CardContainer>

      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">Confirm Deletion</Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to delete this entry?</Typography>
          <MUIButton variant="contained" color="secondary" onClick={confirmDelete}>Yes, Delete</MUIButton>
          <MUIButton variant="contained" onClick={() => setDeleteModalOpen(false)}>Cancel</MUIButton>
        </Box>
      </Modal>

      {isModalOpen && (
        <EditModal
          entry={currentEntry}
          onClose={handleCloseModal}
          onSave={handleSave}
          onChange={handleChange}
          isNew={isNewEntry}
        />
      )}

      {isHeroModalOpen && (
        <EditHeroCasiModal
          entry={currentEntry}  // Pass currentEntry to the modal
          onClose={handleHeroModalClose}
          onSave={handleHeroSave}
          onChange={handleChange}
          isNew={isNewEntry}
        />
      )}
    </Container>
  );
};

export default ManageEntries;
