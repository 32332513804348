import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';


// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    overflow: hidden;
  }  
`;

const DuelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: calc(100vh - 10px);
  box-sizing: border-box;
  overflow-y: hidden;
`;

const CompetitionDisplay = styled.div`
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  justify-content: space-between;
  transform: scale(0.9);
`;

const PlayerWrapper = styled.div`
  padding: 10px;
  background-color: rgba(43, 43, 43, 0.61);
  border-radius: 16px;
  color: white;
  position: relative;
  width: 320px;
`;

const PlayerCard = styled.div`
  background-color: rgb(165, 167, 172);
  color: black;
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;

`;

const ImgWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  i {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const PlayerName = styled.span`
  font-size: 24px;
  font-weight: 900;
  white-space: nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5px;
`;

const GameName = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: white;
`;

const PlayerValue = styled.span`
  font-weight: bold;
  font-size: 24px;
  color: gold;
`;

const Separator = styled.div`
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  .text {
    margin: 5px 10px;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1px;
    color: rgb(255, 68, 43);
  }

  .line {
    height: 50px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-bottom: 10px;
  }

  .line2 {
    height: 50px;
    width: 3px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-top: 10px;
  }
`;

const RegisteredPlayersList = styled.div`
  width: 600px;
  height: 270px; // Höhe für 3 Spieler (3 * 90px)
  overflow: hidden;
  position: relative;
`;

const RegisteredPlayersInner = styled.div`
  position: absolute;
  width: 100%;
  transition: transform 0.5s ease;
`;

const RegisteredPlayerItem = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
`;

const PlayerImage = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    color: #fff;
    font-size: 30px;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlayerNameRegiPlayer = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  color: white;
`;

const GameNameRegiPlayer = styled.span`
  font-size: 20px;
  line-height: 1.2;
  margin-top: 10px;
  color: white;
`;

const API_URL = process.env.REACT_APP_API_URL;

const ViewerViewActualDuel = () => {
  const [currentDuel, setCurrentDuel] = useState(null);
  const [registeredPlayers, setRegisteredPlayers] = useState([]);
  const [isTournamentActive, setIsTournamentActive] = useState(false);
  const [error, setError] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRef = useRef(null);
  const animationRef = useRef(null);

  const fetchTournamentStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/tournamentStatus`);
      setIsTournamentActive(response.data.isTournamentActive_Single);
    } catch (error) {
      console.error('Error fetching tournament status:', error);
    }
  };

  const fetchCurrentDuel = async () => {
    if (!isTournamentActive) return;
    try {
      const response = await axios.get(`${API_URL}/currentSingleDuel`);
      setCurrentDuel(response.data);
      setError(null);
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Error fetching current duel');
      setCurrentDuel(null);
    }
  };

  const fetchRegisteredPlayers = async () => {
    try {
      const response = await axios.get(`${API_URL}/registeredPlayers`);
      setRegisteredPlayers(response.data);
    } catch (error) {
      console.error('Error fetching registered players:', error);
    }
  };

  const animate = useCallback(() => {
    const scrollHeight = scrollRef.current?.scrollHeight || 0;
    const visibleHeight = 270; // RegisteredPlayersList height
    const totalScrollDistance = scrollHeight - visibleHeight;

    const scrollSpeed = 1.5; // Adjust this value to change the scroll speed
    const scrollDuration = totalScrollDistance / scrollSpeed * 100;

    let startTime;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;

      const newScrollPosition = (elapsedTime % scrollDuration) / scrollDuration * totalScrollDistance;
      setScrollPosition(newScrollPosition);

      animationRef.current = requestAnimationFrame(step);
    };

    animationRef.current = requestAnimationFrame(step);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTournamentStatus();
      await fetchRegisteredPlayers();
      if (isTournamentActive) {
        await fetchCurrentDuel();
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);

    if (!isTournamentActive && registeredPlayers.length > 3) {
      animate();
    }

    return () => {
      clearInterval(interval);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isTournamentActive, registeredPlayers.length, animate]);

  const renderPlayerCard = (player) => {
    if (!player) return null;

    const xFactor = player.buyValue > 0 ? (parseFloat(player.value) / parseFloat(player.buyValue)).toFixed(2) : '0';
  
    return (
      <PlayerCard>
        <ImgWrapper>
          {player.profileImageUrl ? (
            <img src={player.profileImageUrl} alt={player.username} className="profileImg" />
          ) : (
            <i className="fas fa-user"></i>
          )}
        </ImgWrapper>
        <Details>
          <PlayerName>{player.username}</PlayerName>
          <InfoWrapper>
            <GameName>{player.game}</GameName>
            <PlayerValue>
              {xFactor}x
              <br />
              <span style={{ fontSize: '16px', color: 'grey' }}>
                {parseFloat(player.value).toFixed(2)} €
              </span>
            </PlayerValue>
          </InfoWrapper>
        </Details>
      </PlayerCard>
    );
  };

  const renderRegisteredPlayers = () => (
    <RegisteredPlayersList>
      <RegisteredPlayersInner
        ref={scrollRef}
        style={{ transform: `translateY(-${scrollPosition}px)` }}
      >
        {registeredPlayers.map((player, index) => (
          <RegisteredPlayerItem key={index}>
            <PlayerImage>
              {player.profileImageUrl ? (
                <img src={player.profileImageUrl} alt={player.username} />
              ) : (
                <i className="fas fa-user"></i>
              )}
            </PlayerImage>
            <PlayerInfo>
              <PlayerNameRegiPlayer>{player.username} :</PlayerNameRegiPlayer>
              <GameNameRegiPlayer>{player.game}</GameNameRegiPlayer>
            </PlayerInfo>
          </RegisteredPlayerItem>
        ))}
      </RegisteredPlayersInner>
    </RegisteredPlayersList>
  );

  return (
    <>
      <GlobalStyle />
      <DuelWrapper>
        <CompetitionDisplay>
          {isTournamentActive && currentDuel ? (
            <>
              <PlayerWrapper>
                {renderPlayerCard(currentDuel.player1)}
              </PlayerWrapper>
              <Separator>
                <div className="line"></div>
                <span className="text">VS</span>
                <div className="line2"></div>
              </Separator>
              <PlayerWrapper>
                {renderPlayerCard(currentDuel.player2)}
              </PlayerWrapper>
            </>
          ) : (
            renderRegisteredPlayers()
          )}
        </CompetitionDisplay>
      </DuelWrapper>
    </>
  );
};

export default ViewerViewActualDuel;