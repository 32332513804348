import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import tmi from 'tmi.js';
import { getToken, getUserInfo } from '../backend/twitchService';

const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS;
const TWITCH_USER_ID = process.env.REACT_APP_TWITCH_USER_ID;
const CHANNEL_NAME = process.env.REACT_APP_CHANNEL_NAME;

const Content = styled.div`
  padding: 20px;
  flex-grow: 1;
  background-color: #ecf0f1;
  overflow-y: auto;
  height: 100vh;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
`;

const PlayerList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`;

const TeamList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const Team = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const PlayerListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  width: 70%;
  border: 1px solid #ddd;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;

    span {
      font-size: 16px;
    }

    .label {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
`;

const PlayerValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;

  span {
    margin-right: 5px;
    font-size: 16px;
  }

  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .value {
    font-size: 16px;
  }
`;

const PlayerActions = styled.div`
  display: flex;
  align-items: center;
`;

const ControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  background-color: #7724cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #772ce8;
  }
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const SettingsButton = styled.button`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  background-color: #7724cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  &:hover {
    background-color: #772ce8;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4d;
  &:hover {
    background-color: #e60000;
  }
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BotControlPanel = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 48%; /* Adjusted to fit two containers side by side */
`;

const PlayerControlPanel = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 48%; /* Adjusted to fit two containers side by side */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
`;

const TournamentStatus = styled.div`
  font-size: 18px;
  background-color: ${props => (props.$active ? '#27ae60' : props.$registrationOpen ? '#f39c12' : '#c0392b')};
  color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const PrizeContainer = styled.div`
  margin-bottom: 10px;
`;

const PrizeLabel = styled.span`
  display: inline-block;
  width: 50px;
`;

const PrizeInput = styled(Input)`
  width: 80px;
`;

const PrizeSelect = styled.select`
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
`;

const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ToggleSwitchLabel = styled.label`
  margin-left: 10px;
`;

const ToggleSwitch = styled.input`
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &:checked {
    background-color: #4caf50;
  }

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    transition: transform 0.3s;
  }

  &:checked:before {
    transform: translateX(20px);
  }
`;

// Additional styles for the authentication prompt
const AuthPrompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 5px;
`;

const AuthStatus = styled.div`
  font-size: 18px;
  background-color: ${props => (props.$authenticated ? '#27ae60' : '#c0392b')};
  color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;  // Cursor ändern
  &:hover {
    opacity: 0.8;
  }
`;


const StreamerView = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  const [authDeclined, setAuthDeclined] = useState(localStorage.getItem('authDeclined') === 'true');
  const [token, setToken] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [bet, setBet] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const [playerCount, setPlayerCount] = useState(1);
  const [registeredTeamDuelPlayers, setRegisteredTeamDuelPlayers] = useState([]);
  const [client, setClient] = useState(null);
  const [mode, setMode] = useState('teamDuel');
  const [teams, setTeams] = useState([]);
  const [teamValues, setTeamValues] = useState({});
  const [activeSection, setActiveSection] = useState('teamDuel');
  const [botCounter, setBotCounter] = useState(1);
  const [botGame, setBotGame] = useState('');
  const [showBotInput, setShowBotInput] = useState(false);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [editingPlayer, setEditingPlayer] = useState(null);
  const [isTournamentActive, setIsTournamentActive] = useState(false);
  const [teamDuelState, setTeamDuelState] = useState({ round: 1, teams: Array.from({ length: 4 }, () => [null, null]) });
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [prizeMoney, setPrizeMoney] = useState('');
  const [prizes, setPrizes] = useState([]);
  const [prizesDraft, setPrizesDraft] = useState([]);
  const [showPlayerInput, setShowPlayerInput] = useState(false);
  const [newPlayerUsername, setNewPlayerUsername] = useState('');
  const [newPlayerGame, setNewPlayerGame] = useState('');
  const [newPlayerPlatform, setNewPlayerPlatform] = useState('');
  const [autoPoints, setAutoPoints] = useState(false);
  const [viewerCount, setViewerCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalPlayer, setModalPlayer] = useState(null);
  const [modalValue, setModalValue] = useState('');
  const [modalBuyValue, setModalBuyValue] = useState('');
  const [modalGame, setModalGame] = useState('');
  const [ws, setWs] = useState(null);

  const WS_URL = process.env.REACT_APP_WS_URL;

  const fetchInitialData = async () => {
    try {
      const [registeredPlayersRes, modeRes, teamDuelStateRes, statusRes] = await Promise.all([
        axios.get(`${SERVER_ADDRESS}/registeredPlayers`),
        axios.get(`${SERVER_ADDRESS}/currentMode`),
        axios.get(`${SERVER_ADDRESS}/teamDuelState`),
        axios.get(`${SERVER_ADDRESS}/tournamentStatus`)
      ]);

      console.log('Registered players:', registeredPlayersRes.data);
      console.log('Current mode:', modeRes.data.mode);
      console.log('Team duel state:', teamDuelStateRes.data);
      console.log('Tournament status:', statusRes.data);

      setRegisteredTeamDuelPlayers(registeredPlayersRes.data);
      setMode(modeRes.data.mode);
      setIsRegistrationOpen(statusRes.data.isRegistrationOpen);
      setIsTournamentActive(statusRes.data.isTournamentActive);
      if (statusRes.data.isTournamentActive) {
        setTeamDuelState(teamDuelStateRes.data);
        setTeams(teamDuelStateRes.data.teams);
        setCurrentPlayerIndex(teamDuelStateRes.data.currentPlayerIndex || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const connectWebSocket = () => {
      const socket = new WebSocket(`wss://${WS_URL}?clientType=TeamDuell`);

      socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'new_player_registered') {
          fetchInitialData(); // Fetch the latest data
        }
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed, reconnecting...');
        setTimeout(connectWebSocket, 1000);
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      setWs(socket);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [isAuthenticated, authDeclined]);
  
  


  useEffect(() => {
    if (!isAuthenticated || authDeclined) return;
    
    const fetchTokenAndData = async () => {
      try {
        const token = await getToken();
        console.log('Token fetched:', token);
        setToken(token);

        const fetchData = async () => {
          const [registeredPlayersRes, modeRes, teamDuelStateRes, statusRes] = await Promise.all([
            axios.get(`${SERVER_ADDRESS}/registeredPlayers`),
            axios.get(`${SERVER_ADDRESS}/currentMode`),
            axios.get(`${SERVER_ADDRESS}/teamDuelState`),
            axios.get(`${SERVER_ADDRESS}/tournamentStatus`)
          ]);

          console.log('Registered players:', registeredPlayersRes.data);
          console.log('Current mode:', modeRes.data.mode);
          console.log('Team duel state:', teamDuelStateRes.data);
          console.log('Tournament status:', statusRes.data);

          setRegisteredTeamDuelPlayers(registeredPlayersRes.data);
          setMode(modeRes.data.mode);
          setIsRegistrationOpen(statusRes.data.isRegistrationOpen);
          setIsTournamentActive(statusRes.data.isTournamentActive);
          if (statusRes.data.isTournamentActive) {
            setTeamDuelState(teamDuelStateRes.data);
            setTeams(teamDuelStateRes.data.teams);
            setCurrentPlayerIndex(teamDuelStateRes.data.currentPlayerIndex || 0);
          }
        };
        
        await fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTokenAndData();
  }, [isAuthenticated, authDeclined]);

  useEffect(() => {
    if (!token || authDeclined) return;

    const initializeClient = async () => {
      try {
        const newClient = new tmi.Client({
          options: { debug: true },
          identity: {
            username: CHANNEL_NAME,
            password: `oauth:${token}`
          },
          channels: [CHANNEL_NAME]
        });

        await newClient.connect();

        newClient.on('message', async (channel, tags, message, self) => {
          if (self) return;

          const username = tags['display-name'];

          if (message.startsWith('!teamduell')) {
            const args = message.split(' ');
            const playerName = username;
            const playerMode = 'teamDuel';
            const game = args.slice(1).join(' ');

            try {
              const { data: status } = await axios.get(`${SERVER_ADDRESS}/registrationStatus`);

              if (!status.isRegistrationOpen) {
                newClient.say(CHANNEL_NAME, 'Teamduell Anmeldephase leider geschlossen');
              } else {
                const response = await axios.post(`${SERVER_ADDRESS}/register`, {
                  username,
                  playerName,
                  game,
                  platform: 'Twitch'
                });

                if (response.data.status === 'game_updated') {
                  const updatedPlayer = response.data.player;
                  setRegisteredTeamDuelPlayers((prevPlayers) =>
                    prevPlayers.map((player) =>
                      player.username === updatedPlayer.username ? updatedPlayer : player
                    )
                  );
                  setTeams((prevTeams) =>
                    prevTeams.map((team) =>
                      team.map((player) =>
                        player && player.username === updatedPlayer.username
                          ? { ...player, game: updatedPlayer.game }
                          : player
                      )
                    )
                  );
                } else {
                  console.log(response.data.message);
                  setRegisteredTeamDuelPlayers((prev) => [...prev, response.data.player]);
                  setTeams((prev) => {
                    const updatedTeams = [...prev];
                    updatedTeams.push([response.data.player, null]);
                    return updatedTeams;
                  });
                }
              }
            } catch (error) {
              console.error('Error registering player:', error.response ? error.response.data : error.message);
            }
          }
        });

        setClient(newClient);

        return () => {
          newClient.disconnect();
        };
      } catch (error) {
        console.error('Error initializing client:', error);
      }
    };

    initializeClient();
  }, [token, authDeclined]);



  const handleAuthStatusClick = () => {
    localStorage.removeItem('authDeclined');
    localStorage.removeItem('isAuthenticated');
    setAuthDeclined(false);
    setIsAuthenticated(false);
  };

  const handleYesClick = () => {
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
  };

  const handleNoClick = () => {
    localStorage.setItem('authDeclined', 'true');
    setAuthDeclined(true);
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teamDuelStateRes = await axios.get(`${SERVER_ADDRESS}/teamDuelState`);
        setTeamDuelState(teamDuelStateRes.data);
        setTeams(teamDuelStateRes.data.teams);
        setCurrentPlayerIndex(teamDuelStateRes.data.currentPlayerIndex || 0);
      } catch (error) {
        console.error('Error fetching team duel state:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 5000); // Fetch data every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchViewerCount = async () => {
      try {
        const response = await axios.get(`${SERVER_ADDRESS}/viewerCount/${TWITCH_USER_ID}`);
        setViewerCount(response.data.viewerCount);
      } catch (error) {
        console.error('Error fetching viewer count:', error);
      }
    };

    fetchViewerCount();
    const interval = setInterval(fetchViewerCount, 60000); // Fetch viewer count every 60 seconds

    return () => clearInterval(interval);
  }, [token]);

  const handleOpenRegistration = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/openRegistration`, {
        tournamentType: 'teamDuel'
      });
      setIsRegistrationOpen(true);
      client.say(CHANNEL_NAME, 'Teamduell Anmeldephase gestartet ... Registriere dich jetzt mit !teamduell <Spielename> um am Turnier teilzunehmen. Die Spielregeln findest du hier: https://google.de');
      alert(response.data.message);
    } catch (error) {
      console.error('Error opening registration:', error.response ? error.response.data : error.message);
    }
  };

  const handleCloseRegistration = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/closeRegistration`, {
        tournamentType: 'teamDuel'
      });
      setIsRegistrationOpen(false);
      alert(response.data.message);
    } catch (error) {
      console.error('Error closing registration:', error.response ? error.response.data : error.message);
    }
  };

  const handleSelectPlayers = () => {
    // TODO: Select players logic
  };

  const handleChangeMode = async (newMode) => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/changeMode`, { mode: newMode });
      setMode(newMode);
      setActiveSection(newMode);

      // Notify viewer view to change mode
      await axios.post(`${SERVER_ADDRESS}/changeModeViewer`, { mode: newMode });
    } catch (error) {
      console.error('Fehler beim Ändern des Modus:', error);
    }
  };

  const handleStartTeamDuel = async () => {
    handleCloseRegistration();
    try {
      const registeredPlayersRes = await axios.get(`${SERVER_ADDRESS}/registeredPlayers`);
      const teamDuelPlayers = registeredPlayersRes.data.filter(player => player.mode === 'teamDuel');

      // Shuffle players
      const shuffledPlayers = teamDuelPlayers.sort(() => Math.random() - 0.5);

      const teams = [];
      for (let i = 0; i < shuffledPlayers.length; i += 2) {
        if (shuffledPlayers[i + 1]) {
          teams.push([shuffledPlayers[i], shuffledPlayers[i + 1]]);
        } else {
          teams.push([shuffledPlayers[i], null]); // Use null to fill empty spots
        }
      }

      const response = await axios.post(`${SERVER_ADDRESS}/startTeamDuel`, { teams });
      setTeams(response.data.state.teams);
      setCurrentPlayerIndex(0); // Start with the first player for value input
      setIsTournamentActive(true); // Set the tournament as active
      alert('Teamduell gestartet');
    } catch (error) {
      console.error('Fehler beim Starten des Teamduells:', error);
    }
  };

  const handleUpdatePlayerValue = async () => {
    const player = teams.flat()[currentPlayerIndex];
    if (!player) return;

    try {
      const response = await axios.post(`${SERVER_ADDRESS}/updatePlayerValue`, {
        username: player.username,
        value: teamValues[player.username] !== undefined ? teamValues[player.username] : 0
      });

      setTeams(prevTeams => {
        const updatedTeams = prevTeams.map(team => 
          team.map(p => p && p.username === player.username ? { ...p, value: teamValues[player.username] !== undefined ? teamValues[player.username] : 0 } : p)
        );
        return updatedTeams;
      });

      if (response.data.currentPlayerIndex === 0) {
        // Fetch the updated state which should now be the next round
        const nextRoundResponse = await axios.get(`${SERVER_ADDRESS}/teamDuelState`);
        setTeams(nextRoundResponse.data.teams);
        setCurrentPlayerIndex(nextRoundResponse.data.currentPlayerIndex);
        setTeamValues({}); // Reset teamValues for the new round
      } else {
        setCurrentPlayerIndex(response.data.currentPlayerIndex);
      }

      alert('Spielerwert aktualisiert');
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Spielerwerts:', error);
    }
  };

  const handleEditPlayerValue = (player) => {
    setModalPlayer(player);
    setModalValue(player.value || '');
    setModalBuyValue(player.buyValue || '');
    setModalGame(player.game || '');
    setShowModal(true);
  };

  const handleSavePlayerValue = async () => {
    if (!modalPlayer) return;
  
    try {
      await axios.post(`${SERVER_ADDRESS}/updatePlayerValue`, {
        username: modalPlayer.username,
        value: modalValue === '' ? 0 : modalValue, // Set to 0 if empty
        buyValue: modalBuyValue === '' ? 0 : modalBuyValue, // Set to 0 if empty
        game: modalGame
      });
  
      setTeams(prevTeams => {
        const updatedTeams = prevTeams.map(team =>
          team.map(p => p && p.username === modalPlayer.username ? { ...p, value: modalValue, buyValue: modalBuyValue, game: modalGame } : p)
        );
        return updatedTeams;
      });
  
      setShowModal(false);
      alert('Spielerwert aktualisiert');
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Spielerwerts:', error);
    }
  };

  const handleTeamValueChange = (field, value) => {
    if (field === 'value') setModalValue(value);
    if (field === 'buyValue') setModalBuyValue(value);
    if (field === 'game') setModalGame(value);
  };

  const handleAddBot = () => {
    setShowBotInput(true);
  };

  const handleRegisterPlayer = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/register`, {
        username: newPlayerUsername,
        playerName: newPlayerUsername,
        game: newPlayerGame,
        platform: newPlayerPlatform,
        tournamentType: 'teamDuel'
      });
  
      if (response.data.status === 'success') {
        setRegisteredTeamDuelPlayers(prev => [...prev, response.data.player]);
        setNewPlayerUsername('');
        setNewPlayerGame('');
        setNewPlayerPlatform('');
        setShowPlayerInput(false);
        alert(`${newPlayerUsername} wurde erfolgreich für Team Duel registriert.`);
      } else {
        alert('Fehler bei der Registrierung: ' + response.data.message);
      }
    } catch (error) {
      console.error('Fehler beim Registrieren des Spielers:', error.response ? error.response.data : error.message);
      alert('Fehler beim Registrieren des Spielers. Bitte versuchen Sie es erneut.');
    }
  };
  
  const handleRegisterBot = async () => {
    const botName = `GBW_Bot_${botCounter}`;
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/register`, {
        username: botName,
        playerName: botName,
        game: botGame,
        platform: 'Bot',
        isBot: true,
        tournamentType: 'teamDuel'
      });
      
      setRegisteredTeamDuelPlayers((prev) => [...prev, response.data.player]);
      setBotCounter(prev => prev + 1);
      setBotGame('');
      setShowBotInput(false);
      alert(`${botName} für Team Duel registriert`);
    } catch (error) {
      console.error('Fehler beim Registrieren des Bots:', error.response ? error.response.data : error.message);
    }
  };
  
  const fetchRegisteredPlayers = async () => {
    try {
      const response = await axios.get(`${SERVER_ADDRESS}/registeredPlayers`, {
        params: { tournamentType: 'teamDuel' }
      });
      console.log('Fetched Team Duel players:', response.data);
      setRegisteredTeamDuelPlayers(response.data);
    } catch (error) {
      console.error('Error fetching Team Duel players:', error);
    }
  };

  const handleRemovePlayer = async (username) => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/removePlayer`, { username });
      setRegisteredTeamDuelPlayers((prev) => prev.filter(player => player && player.username !== username));
      setTeams(prev => {
        // Remove player from teams
        return prev.map(team => team.map(player => player && player.username !== username ? player : null));
      });
      alert(response.data.message);
    } catch (error) {
      console.error('Fehler beim Entfernen des Spielers:', error.response ? error.response.data : error.message);
    }
  };

  const handleResetTournament = async () => {
    try {
      handleCloseRegistration();
      const response = await axios.post(`${SERVER_ADDRESS}/resetTournament`);
      setRegisteredTeamDuelPlayers([]);
      setTeams([]);
      setIsTournamentActive(false); // Setze das Turnier als inaktiv
      setCurrentPlayerIndex(0);
      setTeamValues({});
      alert(response.data.message);
    } catch (error) {
      console.error('Fehler beim Zurücksetzen des Turniers:', error.response ? error.response.data : error.message);
    }
  };

  const handleTournamentEnd = async () => {
    try {
      const response = await axios.get(`${SERVER_ADDRESS}/teamDuelState`);
      const { teams } = response.data;

      if (autoPoints) {
        // Berechne die Gesamtpunkte für jedes Team
        const teamScores = teams.map(team => {
          const totalValue = team.reduce((sum, player) => sum + (player ? player.value : 0), 0);
          return { team, totalValue };
        });

        // Sortiere die Teams basierend auf den Gesamtpunkten absteigend (höchste Punkte zuerst)
        const sortedTeams = teamScores.sort((a, b) => b.totalValue - a.totalValue);

        // Vergib die Preise basierend auf der sortierten Reihenfolge
        const teamResults = sortedTeams.map((entry, index) => ({
          team: entry.team,
          prize: prizes[index] ? prizes[index].value : 0,
        }));

        for (const { team, prize } of teamResults) {
          for (const player of team) {
            if (player && player.platform.toLowerCase() === 'twitch' && prize > 0) {
              const message = `!addpoints ${player.username} ${prize}`;
              await client.say(CHANNEL_NAME, message);
            }
          }
        }
        alert('Punkte wurden automatisch vergeben');
      }

      alert('Turnier wurde beendet');
      setIsTournamentActive(false); // Setze das Turnier als inaktiv
    } catch (error) {
      console.error('Fehler beim Beenden des Turniers:', error);
    }
  };

  const TournamentSettings = ({ autoPoints, prizes }) => {
    return (
      <div style={{ marginLeft: '20px' }}>
        <h3>Aktuelle Turniereinstellungen</h3>
        <ul>
          <li>Automatische Punktevergabe: {autoPoints ? 'Aktiv' : 'Inaktiv'}</li>
          {prizes.map((prize, index) => (
            <li key={index}>
              Platz {index + 1}: {prize.value} {prize.currency} ({prize.location})
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderStatistics = () => (
    <Card>
      <h3>Statistiken</h3>
      <p>Statistiken Inhalt...</p>
    </Card>
  );

  const renderPrizeFields = () => {
    return prizesDraft.map((prize, index) => (
      <PrizeContainer key={prize.id}>
        <PrizeLabel>Platz {index + 1}:</PrizeLabel>
        <PrizeInput
          type="text"
          value={prize.value}
          onChange={(e) => handlePrizeChange(prize.id, 'value', e.target.value)}
          placeholder="Preisgeld"
        />
        <PrizeSelect
          value={prize.currency}
          onChange={(e) => handlePrizeChange(prize.id, 'currency', e.target.value)}
        >
          <option value="EUR">EUR</option>
          <option value="Punkte">Punkte</option>
        </PrizeSelect>
        <PrizeInput
          type="text"
          value={prize.location}
          onChange={(e) => handlePrizeChange(prize.id, 'location', e.target.value)}
          placeholder="Ausschüttungsort"
        />
        <DeleteButton onClick={() => handleRemovePrize(prize.id)}>Entfernen</DeleteButton>
      </PrizeContainer>
    ));
  };

  const handleOpenSettings = () => {
    setPrizesDraft([...prizes]);  // Start editing a copy of the prizes
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleSaveSettings = async () => {
    try {
      // Save the changes to the server
      const response = await axios.post(`${SERVER_ADDRESS}/saveTournamentSettings`, {
        prizes: prizesDraft,
        autoPoints
      });

      if (response.data.status === 'success') {
        setPrizes(prizesDraft);  // Save the changes to the original prizes in state
        localStorage.setItem('prizes', JSON.stringify(prizesDraft)); // Optionally save to local storage
        setShowSettings(false);
        alert('Settings saved');
      } else {
        alert('Error saving settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings');
    }
  };

  const startNextRound = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/nextRound`);
      if (response.data.status === 'success') {
        setTeamDuelState(response.data.state);
      } else {
        console.error('Error starting next round:', response.data.message);
      }
    } catch (error) {
      console.error('Error starting next round:', error);
    }
  };

  const handleAddPrize = () => {
    setPrizesDraft(prevPrizes => [
      ...prevPrizes,
      { id: prevPrizes.length + 1, value: '', currency: 'EUR', location: '' }
    ]);
  };

  const handleRemovePrize = (id) => {
    setPrizesDraft(prevPrizes => prevPrizes.filter(prize => prize.id !== id));
  };

  const handlePrizeChange = (id, field, value) => {
    setPrizesDraft(prevPrizes => prevPrizes.map(prize =>
      prize.id === id ? { ...prize, [field]: value } : prize
    ));
  };

  useEffect(() => {
    const savedPrizes = JSON.parse(localStorage.getItem('prizes')) || [];
    setPrizes(savedPrizes);
  }, []);

  useEffect(() => {
    localStorage.setItem('prizes', JSON.stringify(prizes));
  }, [prizes]);

  const renderTeamDuel = () => (
    <Card>
      <AuthStatus $authenticated={isAuthenticated} onClick={handleAuthStatusClick}>
        {isAuthenticated ? 'Twitch Authenticated' : 'Not Authenticated'}
      </AuthStatus>
      <h3>Team Duel</h3>
      <ControlPanel>
        <ButtonContainer>
          <SettingsButton onClick={handleOpenSettings}>Einstellungen</SettingsButton>
          <TournamentSettings autoPoints={autoPoints} prizes={prizes} />
        </ButtonContainer>
        <ButtonContainer>
          <div>
            <Button
              onClick={handleOpenRegistration}
              disabled={isTournamentActive || isRegistrationOpen}
            >
              Anmeldephase starten
            </Button>
            <Button
              onClick={handleCloseRegistration}
              disabled={isTournamentActive || !isRegistrationOpen}
            >
              Anmeldephase beenden
            </Button>
            <Button
              onClick={handleStartTeamDuel}
              disabled={isTournamentActive || (registeredTeamDuelPlayers.length + teams.flat().filter(player => player && player.isBot).length < 16)}
            >
              Turnier starten
            </Button>
            <Button 
            onClick={startNextRound}
            disabled={!isTournamentActive}
            >
              Nächste Runde
              </Button>
          </div>
  
          <Button
            onClick={handleTournamentEnd}
            disabled={!isTournamentActive}
          >
            Turnier beenden
          </Button>
          <Button onClick={handleResetTournament}>Turnier zurücksetzen</Button>
  
        </ButtonContainer>
      </ControlPanel>
      <TeamList>
        <h2>{isTournamentActive ? 'Spieler im Turnier' : 'Registrierte Spieler'}</h2>
        {isTournamentActive ? (
          teams.map((team, teamIndex) => (
            <Team key={teamIndex}>
              <h3>Team {teamIndex + 1}</h3>
              {team.map((player, playerIndex) => player && (
                <PlayerListItem
                  key={player.username}
                  onClick={() => handleEditPlayerValue(player)}
                >
                  <ProfileImage src={player.profileImageUrl} alt={player.username} />
                  <PlayerInfo>
                    <div>
                      <span className="label">Spielername:</span>
                      <span>{player.username}</span>
                    </div>
                    <div>
                      <span className="label">Game Name:</span>
                      <span>{player.game}</span>
                    </div>
                    <div>
                      <span className="label">Plattform:</span>
                      <span>{player.platform}</span>
                    </div>
                    <PlayerValueContainer>
                      <span className="label">Endergebnis:</span>
                      <span className="value">{player.value} €</span>
                    </PlayerValueContainer>
                  </PlayerInfo>
                  <PlayerActions>
                    <Button onClick={() => handleEditPlayerValue(player)}>Bearbeiten</Button>
                    <DeleteButton onClick={() => handleRemovePlayer(player.username)}>Entfernen</DeleteButton>
                  </PlayerActions>
                </PlayerListItem>
              ))}
            </Team>
          ))
        ) : (
          <PlayerList>
            {registeredTeamDuelPlayers.map((player, index) => (
              <PlayerListItem
                key={index}
              >
                <ProfileImage src={player.profileImageUrl} alt={player.username} />
                <PlayerInfo>
                  <div>
                    <span className="label">Spielername:</span>
                    <span>{player.username}</span>
                  </div>
                  <div>
                    <span className="label">Game Name:</span>
                    <span>{player.game}</span>
                  </div>
                  <div>
                    <span className="label">Plattform:</span>
                    <span>{player.platform}</span>
                  </div>
                  <PlayerValueContainer>
                    <span className="label">Endergebnis:</span>
                    <span className="value">{player.value} €</span>
                  </PlayerValueContainer>
                </PlayerInfo>
                <PlayerActions>
                  <Button onClick={() => handleEditPlayerValue(player)}>Bearbeiten</Button>
                  <DeleteButton onClick={() => handleRemovePlayer(player.username)}>Entfernen</DeleteButton>
                </PlayerActions>
              </PlayerListItem>
            ))}
          </PlayerList>
        )}
      </TeamList>
      <ControlPanelContainer>
        <BotControlPanel>
          <h2>Bot registrieren</h2>
          {!showBotInput && <Button
            onClick={handleAddBot}
            disabled={isTournamentActive}
          >Bot hinzufügen</Button>}
          {showBotInput && (
            <>
              <p>Nächster Bot-Name: GBW_Bot_{botCounter}</p>
              <Input type="text" value={botGame} onChange={(e) => setBotGame(e.target.value)} placeholder="Spiel" />
              <Button onClick={handleRegisterBot}>Bot registrieren</Button>
              <Button onClick={() => setShowBotInput(false)}>Abbrechen</Button>
            </>
          )}
        </BotControlPanel>
        <PlayerControlPanel>
          <h2>Spieler hinzufügen</h2>
          {!showPlayerInput && <Button
            onClick={() => setShowPlayerInput(true)}
            disabled={isTournamentActive}
          >Spieler hinzufügen</Button>}
          {showPlayerInput && (
            <>
              <Input type="text" value={newPlayerUsername} onChange={(e) => setNewPlayerUsername(e.target.value)} placeholder="Benutzername" />
              <Input type="text" value={newPlayerGame} onChange={(e) => setNewPlayerGame(e.target.value)} placeholder="Spiel" />
              <Input type="text" value={newPlayerPlatform} onChange={(e) => setNewPlayerPlatform(e.target.value)} placeholder="Plattform" />
              <Button onClick={handleRegisterPlayer}>Spieler registrieren</Button>
              <Button onClick={() => setShowPlayerInput(false)}>Abbrechen</Button>
            </>
          )}
        </PlayerControlPanel>
      </ControlPanelContainer>
      <TournamentStatus
        $active={isTournamentActive}
        $registrationOpen={isRegistrationOpen}
      >
        {isTournamentActive
          ? 'Turnier in Gange'
          : isRegistrationOpen
            ? 'Anmeldephase läuft'
            : 'Beendet'}
      </TournamentStatus>
  
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Spielerwert aktualisieren für {modalPlayer?.username}</ModalHeader>
            <div>
              <label>Endergebnis:</label>
              <Input
                type="number"
                step="0.01" // Allow decimal values
                value={modalValue}
                onChange={(e) => handleTeamValueChange('value', e.target.value)}
                placeholder="Endergebnis"
              />
            </div>
            <div>
              <label>Einkaufswert:</label>
              <Input
                type="number"
                step="0.01" // Allow decimal values
                value={modalBuyValue}
                onChange={(e) => handleTeamValueChange('buyValue', e.target.value)}
                placeholder="Einkaufswert"
              />
            </div>
            <div>
              <label>Spiel:</label>
              <Input
                type="text"
                value={modalGame}
                onChange={(e) => handleTeamValueChange('game', e.target.value)}
                placeholder="Spiel"
              />
            </div>
            <ModalFooter>
              <Button onClick={handleSavePlayerValue}>Speichern</Button>
              <Button onClick={() => setShowModal(false)}>Abbrechen</Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Card>
  );

  if (!isAuthenticated && !authDeclined) {
    return (
      <Content>
        <AuthPrompt>
          <h1>Streamer View</h1>
          <p>Do you want to authenticate with Twitch?</p>
          <Button onClick={handleYesClick}>Yes</Button>
          <Button onClick={handleNoClick}>No</Button>
        </AuthPrompt>
      </Content>
    );
  }

  return (
    <Content>
      {renderTeamDuel()}
    </Content>
  );
};

export default StreamerView;
