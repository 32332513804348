import React from 'react';
import styled from 'styled-components';
import DiscordIcon from '../assets/Socials/discord.svg';
import TelegramIcon from '../assets/Socials/telegram.svg';

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  color: white;
  margin: 0 10px;

  img {
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <SocialLink href="https://discord.gg/F7PMMzBz4d" target="_blank" rel="noopener noreferrer">
        <img src={DiscordIcon} alt="Discord" />
      </SocialLink>
      <SocialLink href="https://t.me/+n6YlV6xvzCAyYmVi" target="_blank" rel="noopener noreferrer">
        <img src={TelegramIcon} alt="Telegram" />
      </SocialLink>
    </SocialLinksContainer>
  );
};

export default SocialLinks;