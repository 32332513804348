// utils/token.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');

export const isTokenExpired = (token) => {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp * 1000 < Date.now();
};

export const renewToken = async () => {
  const token = getToken();
  if (token && !isTokenExpired(token)) {
    try {
      const response = await axios.post(`${API_URL}/api/renew-token`, {}, {
        headers: { 'x-access-token': token }
      });
      setToken(response.data.token);
    } catch (error) {
      console.error('Token-Erneuerung fehlgeschlagen:', error);
      removeToken();
    }
  }
};
