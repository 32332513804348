import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Filter from './Filter';
import { useMediaQuery } from '@mui/material';

const MainLayout = ({ children, filters, setFilters }) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  const API_URL = process.env.REACT_APP_API_URL;
  const initialRender = useRef(true);

  useEffect(() => {
    if (location.pathname === '/casino-bonus' && initialRender.current) {
      initialRender.current = false; // Set to false immediately to prevent duplicate calls
      fetch(`${API_URL}/api/view`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page: location.pathname })
      }).catch((err) => {
        console.error('Error in API call:', err);
      });
    }
  }, [location.pathname, API_URL]);
  

  return (
    <>
      {location.pathname !== '/manage-entries' && <Header />}
      <div className="main-layout">
        {!isMobile && location.pathname !== '/manage-entries' && <Filter filters={filters} setFilters={setFilters} />}
        {children}
      </div>
    </>
  );
};

export default MainLayout;
