// Filter.js
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import bankTransferIcon from '../assets/Zahlungsmethoden/bank-transfer.svg';
import cryptoIcon from '../assets/Zahlungsmethoden/crypto.svg';
import mastercardIcon from '../assets/Zahlungsmethoden/master-card.png';
import mifinityIcon from '../assets/Zahlungsmethoden/mifinity.svg';
import neosurfIcon from '../assets/Zahlungsmethoden/neosurf.svg';
import netellerIcon from '../assets/Zahlungsmethoden/neteller.svg';
import paysafeIcon from '../assets/Zahlungsmethoden/paysafecard.svg';
import skrillIcon from '../assets/Zahlungsmethoden/skrill.svg';
import visaIcon from '../assets/Zahlungsmethoden/visa.png';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 180px; /* Adjusted max-width to make it narrower */
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    margin-right: 0;
  }
`;

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 1rem;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    padding: '5px 10px',
    zIndex: 2, // Add zIndex to control
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3, // Add zIndex to menu
  }),
  option: (provided, { data }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

const paymentOptions = [
  { value: 'Bank Überweisung', label: 'Bank Überweisung', icon: bankTransferIcon },
  { value: 'Crypto', label: 'Crypto', icon: cryptoIcon },
  { value: 'Master Card', label: 'Master Card', icon: mastercardIcon },
  { value: 'MiFINITY', label: 'MiFINITY', icon: mifinityIcon },
  { value: 'Neosurf', label: 'Neosurf', icon: neosurfIcon },
  { value: 'Neteller', label: 'Neteller', icon: netellerIcon },
  { value: 'Paysafe', label: 'Paysafe', icon: paysafeIcon },
  { value: 'Skrill', label: 'Skrill', icon: skrillIcon },
  { value: 'Visa Card', label: 'Visa Card', icon: visaIcon },
];

const formatOptionLabel = ({ label, icon }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={icon} alt={label} style={{ width: 20, marginRight: 10 }} />
    {label}
  </div>
);

const Filter = ({ filters, setFilters }) => {
  const handleBonusTypeChange = (selectedOptions) => {
    setFilters({
      ...filters,
      filterBonusTypes: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };

  const handlePaymentMethodChange = (selectedOptions) => {
    setFilters({
      ...filters,
      paymentMethods: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };

  return (
    <FilterContainer>
      <FilterTitle>Angebote Filtern: </FilterTitle>
      <br></br>
      <br></br>
      <FilterSection>
        <FilterTitle>Bonus Art:</FilterTitle>
        <Select
          styles={customStyles}
          options={[
            { value: 'Sticky', label: 'Sticky' },
            { value: 'NonSticky', label: 'Non Sticky' },
            { value: 'NoDeposit', label: 'No Deposit' },
            { value: 'Wagerfree', label: 'Wagerfree' },
            { value: 'LowWager', label: 'Low Wager' },
            { value: 'KeinMaxcashout', label: 'Kein Maxcashout' },
          ]}
          isMulti
          onChange={handleBonusTypeChange}
          isClearable
        />
      </FilterSection>
      <FilterSection>
        <FilterTitle>Zahlungsmethoden:</FilterTitle>
        <Select
          styles={customStyles}
          options={paymentOptions}
          isMulti
          onChange={handlePaymentMethodChange}
          formatOptionLabel={formatOptionLabel}
        />
      </FilterSection>
    </FilterContainer>
  );
};

export default Filter;
