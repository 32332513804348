import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

let OAUTH_TOKEN = null;
let tokenExpiration = null;

const userCache = {};

const getToken = async () => {
  if (!OAUTH_TOKEN || Date.now() >= tokenExpiration) {
    const authCode = new URLSearchParams(window.location.search).get('code');
    if (!authCode) {
      window.location = `${API_URL}/api/twitch-auth`;
      return null;
    } else {
      try {
        const response = await axios.post(`${API_URL}/api/twitch-token`, { code: authCode });
        OAUTH_TOKEN = response.data.access_token;
        tokenExpiration = Date.now() + response.data.expires_in * 1000;
        console.log('Token fetched successfully');

        // Remove the authentication code from the URL and keep the /streamer route
        window.history.replaceState({}, document.title, '/dashboard/streamer');
      } catch (error) {
        console.error('Error fetching the token:', error.response ? error.response.data : error.message);
        throw error;
      }
    }
  }
  return OAUTH_TOKEN;
};

const getUserInfo = async (username) => {
  if (userCache[username]) {
    return userCache[username];
  }

  const token = await getToken(); // Update token if necessary

  try {
    const response = await axios.get(`${API_URL}/api/userinfo/${username}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const userInfo = response.data;
    userCache[username] = userInfo;
    return userInfo;
  } catch (error) {
    console.error('Error fetching user info:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const getViewerCount = async (userId) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${API_URL}/api/viewerCount/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data.viewerCount;
  } catch (error) {
    console.error('Error fetching viewer count:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export { getToken, getUserInfo, getViewerCount };