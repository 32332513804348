import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import { Add as AddIcon, Save as SaveIcon, Assessment as AssessmentIcon } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const ScrollableList = styled(Box)({
  maxHeight: 400,
  overflowY: 'auto',
});



const ManageGames = () => {
  const [games, setGames] = useState([]);
  const [newGame, setNewGame] = useState({ name: '', bet: '', bonushunt_id: 1 });
  const [startValue, setStartValue] = useState(0);
  const [isStartValueSaved, setIsStartValueSaved] = useState(true);
  const [balanceBeforeOpening, setBalanceBeforeOpening] = useState(0);
  const [isBalanceBeforeOpeningSaved, setIsBalanceBeforeOpeningSaved] = useState(true);
  const [bonushuntId, setBonushuntId] = useState(1);
  const [selectedBonushuntId, setSelectedBonushuntId] = useState(1);
  const [bonushuntIds, setBonushuntIds] = useState([]);
  const [status, setStatus] = useState('inactive');
  const [openModal, setOpenModal] = useState(false);
  const [evaluationResults, setEvaluationResults] = useState([]);
  const [finalResult, setFinalResult] = useState(0);
  const [avgXValue, setAvgXValue] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCurrentBonushuntId = async () => {
      try {
        const response = await fetch(`${API_URL}/api/current-bonushunt-id`);
        const data = await response.json();
        console.log('Fetched current bonushunt ID:', data);
        setBonushuntId(data.aktuelle_id);
        setSelectedBonushuntId(data.aktuelle_id);
        setNewGame(prevState => ({ ...prevState, bonushunt_id: data.aktuelle_id }));
      } catch (error) {
        console.error('Fetching current bonushunt ID failed:', error);
      }
    };

    const fetchBonushuntIds = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${API_URL}/api/bonushunt-ids`, {
          headers: {
            'x-access-token': token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched bonushunt IDs:', data);
          setBonushuntIds(data);
        } else {
          console.error('Failed to fetch bonushunt IDs:', response.status);
        }
      } catch (error) {
        console.error('Fetching bonushunt IDs failed:', error);
      }
    };

    const fetchStartValue = async () => {
      try {
        const response = await fetch(`${API_URL}/api/start-value/${selectedBonushuntId}`);
        const data = await response.json();
        setStartValue(data.startValue);
      } catch (error) {
        console.error('Fetching start value failed:', error);
      }
    };
    
    const fetchBalanceBeforeOpening = async () => {
      try {
        const response = await fetch(`${API_URL}/api/balance-before-opening?bonushunt_id=${selectedBonushuntId}`);
        if (!response.ok) {
          console.error('Network response was not ok', response.statusText);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched balance before opening:', data);
        setBalanceBeforeOpening(data.balanceBeforeOpening);
      } catch (error) {
        console.error('Fetching balance before opening failed:', error);
      }
    };

    fetchCurrentBonushuntId();
    fetchBonushuntIds();
    fetchStartValue();
    fetchBalanceBeforeOpening();
  }, [selectedBonushuntId]);

  useEffect(() => {
    const fetchGames = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${API_URL}/api/games/${selectedBonushuntId}`, {
          headers: {
            'x-access-token': token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setGames(data.map(game => ({ ...game, saved: true })));
        } else {
          console.error('Failed to fetch games:', response.status);
        }
      } catch (error) {
        console.error('Fetching games failed:', error);
      }
    };

    const fetchStatus = async () => {
      try {
        const response = await fetch(`${API_URL}/api/bonushunt-status/${selectedBonushuntId}`);
        const data = await response.json();
        setStatus(data.status);
      } catch (error) {
        console.error('Fetching bonushunt status failed:', error);
      }
    };

    fetchGames();
    fetchStatus();
  }, [selectedBonushuntId]);

  const handleInputChange = (index, field, value) => {
    const updatedGames = [...games];
    updatedGames[index][field] = value;
    updatedGames[index].saved = false;
    setGames(updatedGames);
  };

  const handleSave = async (index) => {
    const game = games[index];
    const token = localStorage.getItem('token');
    console.log('Saving game:', game);
    try {
      const response = await fetch(`${API_URL}/api/games/${game.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          name: game.name,
          bet: game.bet,
          result: game.result,
          bonushunt_id: game.bonushunt_id
        }),
      });
      if (!response.ok) {
        console.error('Response status:', response.status);
        throw new Error('Network response was not ok');
      }
      const updatedGames = [...games];
      updatedGames[index].saved = true;
      setGames(updatedGames);
    } catch (error) {
      console.error('Saving game failed:', error);
    }
  };

  const handleAddNewGame = async () => {
    const token = localStorage.getItem('token');
    console.log('Adding new game:', newGame);
    try {
      const response = await fetch(`${API_URL}/api/games`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify(newGame),
      });
      if (!response.ok) {
        console.error('Response status:', response.status);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setGames([...games, { ...data, saved: true }]);
      setNewGame({ name: '', bet: '', bonushunt_id: bonushuntId });
    } catch (error) {
      console.error('Adding new game failed:', error);
    }
  };

  const handleSaveStartValue = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/start-value`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ startValue, bonushunt_id: selectedBonushuntId }),
      });
      if (!response.ok) {
        console.error('Response status:', response.status);
        throw new Error('Network response was not ok');
      }
      setIsStartValueSaved(true);
    } catch (error) {
      console.error('Saving start value failed:', error);
    }
  };

  const handleSaveBalanceBeforeOpening = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/balance-before-opening`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ balance: balanceBeforeOpening, bonushunt_id: selectedBonushuntId }),
      });
      if (!response.ok) {
        console.error('Response status:', response.status);
        throw new Error('Network response was not ok');
      }
      setIsBalanceBeforeOpeningSaved(true);
    } catch (error) {
      console.error('Saving balance before opening failed:', error);
    }
  };

  const handleStartNewBonushunt = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/bonushunt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      const data = await response.json();
      setBonushuntId(data.bonushunt_id);
      setSelectedBonushuntId(data.bonushunt_id);
      setNewGame(prevState => ({ ...prevState, bonushunt_id: data.bonushunt_id }));
      setGames([]);
    } catch (error) {
      console.error('Starting new bonushunt failed:', error);
    }
  };

  const handleBonushuntChange = async (e) => {
    const selectedId = e.target.value;
    setSelectedBonushuntId(selectedId);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/update-current-bonushunt-id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ aktuelle_id: selectedId }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Current bonushunt ID updated successfully');
      setBonushuntId(selectedId); // Update the current bonushunt ID state
    } catch (error) {
      console.error('Updating current bonushunt ID failed:', error);
    }
  };

  const toggleBonushuntStatus = async () => {
    const newStatus = status === 'active' ? 'inactive' : 'active';
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/bonushunt-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ bonushunt_id: selectedBonushuntId, status: newStatus }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Bonushunt status updated successfully');
      setStatus(newStatus);
    } catch (error) {
      console.error('Updating bonushunt status failed:', error);
    }
  };

  const closeBonushunt = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/bonushunt-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ bonushunt_id: selectedBonushuntId, status: 'abgeschlossen' }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Bonushunt closed successfully');
      setStatus('abgeschlossen');
    } catch (error) {
      console.error('Closing bonushunt failed:', error);
    }
  };

  const handleOpenModal = async () => {
    try {
      const response = await fetch(`${API_URL}/api/bonushunt-results/${selectedBonushuntId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEvaluationResults(data.results);
      setFinalResult(parseFloat(data.finalResult).toFixed(2));
      setAvgXValue(data.avgXValue);
      setStartValue(data.startValue);
      setBalanceBeforeOpening(parseFloat(data.balanceBeforeOpening).toFixed(2));
      setOpenModal(true);
    } catch (error) {
      console.error('Fetching evaluation results failed:', error);
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography variant="h3" gutterBottom>
          Manage Games
        </Typography>

        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Bonushunt Settings
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
              Current Bonushunt ID: {bonushuntId}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartNewBonushunt}
              startIcon={<AddIcon />}
            >
              New Bonushunt
            </Button>
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle1" gutterBottom>
              Select Bonushunt
            </Typography>
            <Select
              value={selectedBonushuntId}
              onChange={handleBonushuntChange}
              fullWidth
            >
              {bonushuntIds.map(id => (
                <MenuItem key={id.id} value={id.id}>
                  Bonushunt {id.id} ({id.created_at})
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle1" gutterBottom>
              Status
            </Typography>
            <Chip
              label={status === 'active' ? 'Active' : status === 'abgeschlossen' ? 'Closed' : 'Inactive'}
              color={status === 'active' ? 'success' : status === 'abgeschlossen' ? 'default' : 'error'}
            />
            <Button
              variant="outlined"
              color={status === 'active' ? 'error' : 'success'}
              onClick={toggleBonushuntStatus}
              disabled={status === 'abgeschlossen'}
              sx={{ ml: 2 }}
            >
              {status === 'active' ? 'Deactivate' : 'Activate'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={closeBonushunt}
              disabled={status === 'abgeschlossen'}
              sx={{ ml: 2 }}
            >
              Close Bonushunt
            </Button>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Streamer Balance
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              label="Start Value"
              type="number"
              value={startValue}
              onChange={(e) => {
                setStartValue(e.target.value);
                setIsStartValueSaved(false);
              }}
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              color={isStartValueSaved ? 'success' : 'primary'}
              onClick={handleSaveStartValue}
              startIcon={<SaveIcon />}
            >
              {isStartValueSaved ? 'Saved' : 'Save'}
            </Button>
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              label="Balance Before Opening"
              type="number"
              value={balanceBeforeOpening}
              onChange={(e) => {
                setBalanceBeforeOpening(e.target.value);
                setIsBalanceBeforeOpeningSaved(false);
              }}
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              color={isBalanceBeforeOpeningSaved ? 'success' : 'primary'}
              onClick={handleSaveBalanceBeforeOpening}
              startIcon={<SaveIcon />}
            >
              {isBalanceBeforeOpeningSaved ? 'Saved' : 'Save'}
            </Button>
          </Box>
        </Paper>

        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
                <TableCell>Bet</TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Bonushunt ID</TableCell>
                <TableCell>X-Value</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.map((game, index) => (
                <TableRow key={game.id}>
                  <TableCell>
                    <TextField
                      value={game.name}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={game.bet}
                      onChange={(e) => handleInputChange(index, 'bet', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={game.result || ''}
                      onChange={(e) => handleInputChange(index, 'result', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>{game.bonushunt_id}</TableCell>
                  <TableCell>
                    {game.result && game.bet ? (game.result / game.bet).toFixed(2) : ''}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color={game.saved ? 'success' : 'primary'}
                      onClick={() => handleSave(index)}
                    >
                      <SaveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    placeholder="Game Name"
                    value={newGame.name}
                    onChange={(e) => setNewGame({ ...newGame, name: e.target.value })}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    placeholder="Bet"
                    value={newGame.bet}
                    onChange={(e) => setNewGame({ ...newGame, bet: e.target.value })}
                    fullWidth
                  />
                </TableCell>
                <TableCell colSpan={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewGame}
                    startIcon={<AddIcon />}
                    fullWidth
                  >
                    Add Game
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={3} textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenModal}
            disabled={status !== 'abgeschlossen'}
            startIcon={<AssessmentIcon />}
          >
            Ergebnis anzeigen
          </Button>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <ModalBox>
            <Typography variant="h4" gutterBottom>
              Bonushunt Evaluation - ID: {selectedBonushuntId}
            </Typography>
            <Typography variant="subtitle1">
              ({bonushuntIds.find(b => b.id === selectedBonushuntId)?.created_at})
            </Typography>
            <Box mt={2}>
              <Typography>Start Balance: {startValue}€</Typography>
              <Typography>Balance Before Opening: {balanceBeforeOpening}€</Typography>
              <Typography>Final Result: {finalResult}€</Typography>
              <Typography>Average X-Value: {avgXValue}</Typography>
            </Box>
            <ScrollableList mt={2}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Player</TableCell>
                      <TableCell>Predicted Total</TableCell>
                      <TableCell>Difference</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {evaluationResults.map((result, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{result.viewerName}</TableCell>
                        <TableCell>{parseFloat(result.predictedTotal).toFixed(2)}€</TableCell>
                        <TableCell>{parseFloat(result.difference).toFixed(2)}€</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ScrollableList>
          </ModalBox>
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

export default ManageGames;